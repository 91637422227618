.col-encart {
    margin-bottom: 15px;

    .titre-encart {
        font-family: 'antonioregular';
        font-size: 22px;
        line-height: 1.3;
        text-transform: uppercase;
        color: theme-color(white);
        max-width: 70%;
        padding: 10px;
        background: none repeat scroll 0 0 rgba(theme-color(primary), 0.4);
        color: theme-color(white);
        opacity: 0.999;
        box-sizing: border-box;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
    }
    .infos-hover-encart {
        position: absolute;
        top: 0;
        left: -100%;
        transition: all ease 0.3s;
        background: rgba(theme-color(primary),0);
        width: 0;
        height: 0;
        vertical-align: middle;
    }

    a {
        background: theme-color(primary);
        display: block;
        height: 300px;
        position: relative;
        z-index: 3;
        color: theme-color(white);
        overflow: hidden;
        
        &:hover {
            img{
                opacity:0.6;
            }
            .titre-encart {
                max-width: 100%;
                padding: 50px;
                padding-bottom: 10px;
                text-align: center;
                top: 0;
                left: 0;
                background: none;
                opacity: 1;
                display: none;
            }
            .infos-hover-encart {
                display:block;
                position:absolute;
                color: theme-color(white);
                text-align:center;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                z-index: 2;
                padding: 50px;
                padding-top: 0;
                box-sizing: border-box;
                background: rgba(theme-color(primary), 0.8);
                opacity: 1;

                .btn-more {
                    font-family: 'antonioregular';
                    font-size: 16px;
                    line-height: 1.3;
                    text-transform: uppercase;
                    color: theme-color(white);
                    background: theme-color(success);
                    padding: 10px;
                    padding-bottom: 5px;
                    display: block;
                    margin: 15px auto 0 auto;
                    width: 80px;
                }
            }
        }
        .description {
            font-size: 14px;
            vertical-align: middle;
            display: inline-block;
            position: relative;
            top: 50%;
            left: 0;
            transform: translate(0,-55%);
        }
    }
}