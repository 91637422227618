header#header {
    @media(min-width:992px) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 200;
        width: 100%;
    }

    .navbar {
        position: static;
    }
    @media(min-width:768px) and (max-width:991px) {
        .container {
            width: 100%;
        }
    }

    .content-header {
        @extend %transition;
        background: theme-color(white);
        box-shadow: 0px 5px 5px 0px rgba(theme-color(primary),0.3);
        position: relative;
        z-index: 50;
        
        .navbar-brand {
            padding: 10px 0 !important;
            height: auto !important;

            .logo-big {
                display: none;
                position: absolute;
                top: 10px;
                left: 0;
                z-index: 5;
            }

            img {
                @extend %transition;
            }

            @media(max-width:768px) {
                img {
                    width: auto;
                    height: 30px;
                }
            }
        }

        .navbar-toggle {
            border: none !important;
            background: none !important;
            margin-right: 0;

            @media(min-width: 768px) and (max-width: 991px) {
                margin-top: 25px;
            }
        }


        .navbar-golf {
            margin-bottom: 0;
            background-color: transparent;
            border:0;
            border-radius: 0;

            li {
                padding-left: 0;
                padding-right: 40px;
                position: static;

                @media(max-width:1199px) {
                    padding-right: 20px;
                }

                @media(min-width:768px) and (max-width:991px) {
                    padding-right: 15px;
                }

                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }

                & > a {
                    color: theme-color(primary);
                    font-weight: normal;
                    font-family: 'Raleway';
                    font-size: 16px;
                    font-weight: bold;
                    padding: 29px 0;
                    text-transform: uppercase;
                    position: relative;
                    
                    @media(max-width:1199px) {
                        font-size: 15px;
                    }

                    &::after {
                        content: "";
                        width: 0;
                        height: 3px;
                        background: theme-color(secondary);
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        z-index: 2;
                        transform: translateX(-50%) translateY(-1px);
                        @extend %transition;
                    }
                }

                &:hover a, &:focus a, a:hover, a:focus {
                    background-color: transparent;
                    color: theme-color(secondary);

                    &::after {
                        width: 100%;
                    }

                }

                .dropdown-menu {
                    background: theme-color(primary);
                    border-radius: 0;
                    border:0;
                    top: 100%;
                    width: 100%;

                    @media(max-width:768px) {
                        display: none !important;
                    }

                    .submenu {
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }

                    .block-title {
                        font-family: 'antonioregular' !important;
                        color: theme-color(white) !important;
                        font-size: 20px !important;
                        margin-bottom: 20px !important;
                        text-transform: uppercase !important;
                    }
                    .desc-ss-menu {
                        color: theme-color(white);
                        line-height: 18px;
                        height: 200px;
                        line-height: 1.6;
                        overflow: hidden;

                        * {
                            color: theme-color(white) !important;
                        }
                    }
                    .item:not(:last-child) {
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        border-bottom: theme-color(white) solid 1px;
                    }

                    .item [class*="col"]:not(:last-child) {
                        margin-bottom: 8px;
                    }

                    .submenu a {
                        transition: all ease .3s;

                        &:hover, &:focus {
                            background: none !important;
                            text-decoration: none !important;
                            color: theme-color(white) !important;
                            opacity: 0.6;
                        }
                    }

                    a {
                        color: theme-color(white);
                        font-weight: normal;
                        font-family: 'Raleway';
                        font-size: 15px;
                        transition: all 0.3s ease-out;
                        border: none;
                        text-align: left;

                        &::after {
                            display: none;
                        }

                        @media (max-width: 767px){
                            color: theme-color(white);
                        }

                        &:hover, &:focus {
                            background: theme-color(white);
                            color: theme-color(primary);

                            @media (max-width: 767px){
                                color: theme-color(white);
                            }
                        }
                    }
                }
            }
        }
    }
}

/*body.index {
    #header {
        @extend %transition;

        @media(min-width:992px) {            
            &:not(.fixed) {
                .content-header {
                    background: transparent;
                    box-shadow: none;

                    li a {
                        color: theme-color(white);
                    }

                    .logo-big {
                        display: block;
                    }
                    .logo-small {
                        display: none;
                    }
                }
            }
        }
    }
}*/

@media(min-width:992px) {
    #header.fixed {
        position: fixed;
    }
}