.slide-home {
    position: relative;
    
    .item {
        background-blend-mode: hard-light;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;

        @media(max-width: 991px) {
            height: 40vh;
        }
    }
}