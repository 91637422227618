.facebook-faces {
    .title {
        font-weight: bold;
        margin-bottom: 5px;
        color: theme-color(primary);
    }
    ul {
        padding: 0;
        margin-left: -3px;

        li {
            overflow: hidden;
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 30px;
            border: theme-color(white) solid 3px;
            
            &:not(:first-child) {
                margin-left: -10px;
            }

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}