body.recherche-sur-carte {
    #pages {
        margin-top: 119px;
        position: relative;

        #bloc-resultat-carte-content {
            background: transparent !important;

            #item-resultat-carte-content {
                height: 80vh !important;
                padding: 20px 0;
                box-shadow: 0px 0px 8px 0px rgba(theme-color(primary), 0.6);
            }
        }
    }
}