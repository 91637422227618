body.interest-produits {
    .form-interest-product {
        .col-md-6 {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 15px;
        }
        label {
            display: block;
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 15px;
        }
        .form-inline {
            label {
                display: inline-block;
                margin: 0;
            }
            input {
                margin: 0 5px 0 0;
                height: auto;
            }
        }
    }
}