.map {
    position: absolute;
    bottom: 80px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    width: 500px;
    height: auto;

    .country {
        fill: theme-color(white);
        opacity: 0.95; 
    }
    .marker {
        @extend %transition;
        fill: theme-color(marker);
        filter: drop-shadow(4px 4px 2px rgba(theme-color(primary),0.5));
        pointer-events: all;
    }

    @media(max-width: 991px) {
        display: none;
    }

    > img {
        width: 100%;
        height: auto;
        opacity: 0.9;
    }
    .zone{
        width: 31px;
        height: 35px;
        position: absolute;
        cursor: pointer;
        z-index: 1;
    }

    .list{
        position: absolute;
        background-color: rgba(theme-color(primary),0.2);
        border-radius: 4px;
        padding: 6px;
        max-width: 185px;
        margin-left: 20px;
        min-width: 100px;
        margin-top: -70px;
        display: none;
        z-index:10;

        &.open{
            display: block;
        }

        .list-pays{
            background-color: theme-color(white);
            border-radius: 4px;
            padding: 5px 10px;
            min-height: 100px;
            min-width: 173px;

            h3{
                background-color: theme-color(secondary);
                color: theme-color(white);
                border-radius: 3px;
                margin: 4px auto !important;
                padding: 10px 0;
                display: block;
                width: 100%;
                font-size: 20px;
            }

            a{
                text-decoration:none;
                display: block;
                color: lighten(theme-color(primary),20%);
                text-decoration: none;
                padding: 5px 10px;
                font-size: 13px;

                &:hover {
                    background-color: lighten(theme-color(primary),60%);
                }
            }
        }
    }

    .list {
        text-align: center;

        &#list1 {
            left: 175px;
            top: 222px;
        }
        &#list2 {
            left: 130px;
            top: 100px;
        }
        &#list3 {
            left: 105px;
            top: 115px;
        }
        &#list4 {
            left: 145px;
            top: 175px;
        }
        &#list5 {
            left: 174px;
            top: 118px;
        }
        &#list6 {
            left: 257px;
            top: 151px;
        }
        &#list7 {
            left: 257px;
            top: 226px;
        }
        &#list8 {
            left: 162px;
            top: 154px;
        }
        &#list9 {
            left: 203px;
            top: 227px;
        }
        &#list10 {
            left: 201px;
            top: 142px;
        }
        &#list11 {
            left: 263px;
            top: 204px;
        }
        &#list12 {
            left: 189px;
            top: 202px;
        }
        &#list13 {
            left: 267px;
            top: 252px;
        }
        &#list14 {
            left: 67px;
            top: 361px;
        }
        &#list15 {
            left: 82px;
            top: 256px;
        }
        &#list16 {
            left: 183px;
            top: 305px;
        }
        &#list17 {
            left: 579px;
            top: 421px;
        }
        &#list4747 {
            left: 579px;
            top: 421px;
        }
    }
}