.nav-pills {
    @media (min-width: 768px) {
        display: flex;
        justify-content: center;
    }
    margin: 0;

    li {
        float: none;
        position: relative;
        margin: 0 !important;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;

        a {
            border-radius: 0;
            color: theme-color(white);
            background: theme-color(primary);
            font-weight: bold;
            font-size: 16px;
        }

        @media (min-width: 768px) {
            &.active a {
                color: theme-color(primary);
                background: lighten(theme-color(primary), 70%);
            }
        }

        @media (min-width: 768px) {
            &:not(:last-child) {
                border-right: lighten(theme-color(primary), 70%) solid 1px;
            }
        }
        @media (max-width: 767px) {
            &:not(:last-child) {
                border-bottom: lighten(theme-color(primary), 70%) solid 1px;
            }
        }
    }
}