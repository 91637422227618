@import "parts/product-list";

#ajax_recherche_comp {
    margin-bottom: 30px;
}

.slider-sejour-accueil {
    a {
        color: theme-color(primary);
    }
}

.article-complet {
    a {
        color: theme-color(primary);
    }
}

.description {
    .liresuite {
        color: theme-color(secondary);
        font-weight: bold;
    }
}

body.stages-sejours {
    .articles {
        @media (min-width: 1199px) {
            div[class^="col"]:nth-child(odd) {
                clear: both;
            }
        }
    }
}

body.stages,
body.stages-sejours,
body.espace-vip{
    #carousel-home {
        .carousel-caption {
            background: none;
            box-shadow: none;
            padding: 15px;
            height: auto !important;
            color: theme-color(white);
            /*top: 50% !important;*/
            bottom: 30px;
            font-size: 18px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            /*transform: translate(-50%, -50%) !important;*/
            background: rgba(theme-color(primary), 0.8) !important;
            /*position: static !important;
            transform: none !important;
            margin: 15px auto 0 auto;
            height: auto !important;*/
            width: 50%;

            @media(min-width: 992px) and (max-width: 1199px) {
                width: 80%;
            }
    
            @media(max-width: 991px) {
                width: 90%;
            }    
        }
    }
}

 .slider-sejour-list article {
    @extend %article-carousel;
 }