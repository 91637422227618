#breadcrumb-content {
    transition: all ease-in-out .2s;
    padding-top: 25px;
    
    #breadcrumb {
        background: none;

        ul, ol {
            transition: all ease-in-out .2s;
            margin-top: 6px;
            display: inline-block;

            li {
                list-style-type: none;
                display: inline-block;

                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                    padding: 0 8px;
                }

                a {
                    color: theme-color(primary);
                    font-weight: 400;
                    transition: color linear .4s;

                    &:hover {
                        color: theme-color(light);
                    }
                }
            }
        }

        h1 {
            clear: both;
            display: block;
            font-family: Conv_Gotham-Light;
            font-weight: 400;
            font-size: 16px;
            padding-left: 2px;
            color: theme-color(primary);
        }

        #retour-breadcrumb {
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            transition: color linear .4s;

            a {
                padding: 8px;
                background: theme-color(primary);
                color: theme-color(white);
                text-transform: uppercase;
                font-weight: 700;
                text-decoration: none;
                vertical-align: middle;
                display: inline-block;
                transition: all ease .3s;

                span {
                    display: inline-block;
                    vertical-align: middle;
                }

                &::before {
                    content: "<";
                    display: inline-block;
                    margin-right: 5px;
                    vertical-align: middle;
                }

                &:hover, &:focus {
                    background:theme-color(secondary) !important;
                }
            }
        }
    }
}