.product-list {
    .row {
        padding: 0;
    }
    .panel-body {
        display: block;
        padding: 0;

        &:hover {
            header img {
                transform: scale(1.2);
            }
        }

        header {
            position: relative;
            overflow: hidden;

            img {
                @extend %transition;
            }
    
            .label {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 2;
                font-size: 14px;
                border: theme-color(white) solid 1px;
            }
            .content-feature {
                position: absolute;
                top: 15px;
                left: 15px;
                z-index: 2;
                padding: 5px;
                font-size: 32px;
                letter-spacing: 1.2px;

                i {
                    background: theme-color(primary);
                    border-radius: 100%;
                    width: 30px;
                    height: 30px;
                    line-height: 1;
                }

                .heart-feature {
                    display: inline-block;
                    color: theme-color(primary);
                    text-shadow: 1px 1px 0 theme-color(white),
                    1px -1px 0 theme-color(white),
                    -1px 1px 0 theme-color(white),
                    -1px -1px 0 theme-color(white),
                    1px 0px 0 theme-color(white), 0px 1px 0 theme-color(white),
                    -1px 0px 0 theme-color(white),
                    0px -1px 0 theme-color(white);
                }
                .theme-feature {
                    display: inline-block;
                    color: theme-color(white);
                }
            }

            .title {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                padding: 100px 20px 20px 20px;
                color: theme-color(white);
                font-weight: bold;
                font-size: 20px;
                background: linear-gradient(0deg, rgba(theme-color(primary),0.9) 30%, rgba(theme-color(primary),0) 80%);

                span {
                    font-size: 16px;
                    display: block;
                    font-weight: normal;
                }

                .theme {
                    font-size: 5rem;
                    float: left;
                    margin-right: 15px;
                }
            }
        }
        .card-main {
            padding: 15px 20px;
            color: theme-color(primary);

            .text-right {
                color: theme-color(primary);
                font-weight: bold;

                .reviews {
                    vertical-align: middle;
                    .stars {
                        margin: 0;
                        display: inline-block;
                        color: theme-color(secondary);
                        vertical-align: middle;
                    }
                    .total-reviews {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
                .product-price {
                    .price {
                        font-size: 24px;
                    }
                }
            }
        }
    }
    footer {
        text-align: right;
        padding: 0 20px 15px 20px;

        .btn {
            font-size: 12px;
            padding: 8px;
        }
    }
}