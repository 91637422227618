body.listing-golf {
    #recherche-complementaire {
        h2, .h2, h3, .h3 {
            padding: 0;
            margin: 0;
        }
        .button {
            border: none;
            width: 100%;
        }
    }
    .item-golf {
        border: theme-color(light) solid 1px;
        padding: 15px;
        float: left;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .titre-prod {
            a {
                color: theme-color(primary);
                font-size: 2rem;
                font-weight: bold;
                display: block;
                margin-bottom: 15px;
            }
        }
        .atout-golf-flypage {
            list-style-type: none;
            padding: 0;
            margin: 15px 0;

            li {
                display: inline-block;

                &:not(:last-child)::after {
                    content: ", ";
                }
            }
        }
        .lien-details-prod a {
            background: theme-color(primary);
            height: 70px;
        }
    }
}