
$theme-colors: (
    primary: #333333,
    secondary: #c9a651,
    white: #ffffff,
    black: #000000,
    youtube: #ff0000,
    facebook:#1877f2,
    linkedin: #006699,
    google:#cf472d,
    pinterest: #ad2525,
    marker: #fc533f,
    light: #999,
    success: #5cb85c
);

@function theme-color($name) {
    @return map-get($theme-colors, $name);
}
@function theme-text-color($color) {
    @if (lightness($color) > 40) {
        @return theme-color(primary);
    } @else {
        @return theme-color(white);
    }
};
@each $name, $color in $theme-colors {
    &.bg-#{$name} {
        color: theme-color(white);
        background: $color;
        &.bg-hover:hover {
            background: lighten($color, 20%);
        }
    }
}
%transition {
    transition: all .25s ease;
}