@font-face {
    font-family: "icon";
    src: url('../icons/icon.eot');
    src: url('../icons/icon.eot?#iefix') format('eot'),
      url('../icons/icon.woff2') format('woff2'),
      url('../icons/icon.woff') format('woff'),
      url('../icons/icon.ttf') format('truetype'),
      url('../icons/icon.svg#icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .icon{
    display:inline-block;
  }
  .icon:before {
    display: inline-block;
    font-family: "icon";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-lg {
    font-size: 1.3333333333333333em;
  }
  .icon-2x { font-size: 2em; }
  .icon-3x { font-size: 3em; }
  .icon-4x { font-size: 4em; }
  .icon-5x { font-size: 5em; }
  .icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
  }
  
  .icon-15:before { content: "\E001" }
  .icon-25h:before { content: "\E002" }
  .icon-bestsell:before { content: "\E003" }
  .icon-familly:before { content: "\E004" }
  .icon-fit:before { content: "\E005" }
  .icon-food:before { content: "\E006" }
  .icon-golf:before { content: "\E007" }
  .icon-green-card:before { content: "\E008" }
  .icon-greentic:before { content: "\E009" }
  .icon-health:before { content: "\E00A" }
  .icon-heart:before { content: "\E00B" }
  .icon-little-game:before { content: "\E00C" }
  .icon-mind:before { content: "\E00D" }
  .icon-mrp:before { content: "\E00E" }
  .icon-multigolfs:before { content: "\E00F" }
  .icon-run:before { content: "\E010" }
  .icon-sale:before { content: "\E011" }
  .icon-spa:before { content: "\E012" }
  .icon-trackman:before { content: "\E013" }
  .icon-vip:before { content: "\E014" }
  