.login-form {
	width: 400px;
    border-radius: 0 0 3px 3px;
    background: rgba(theme-color(white), 0.7);
    position: absolute;
    z-index: 9;
    top: 35px;
    padding: 20px;
    text-align: left;
    box-shadow: 0px 0px 8px 1px rgba(theme-color(primary), 0.4);

    img.logo-small {
        display: block;
        margin: 0 auto 15px auto;
    }

    .h1 {
        text-align: center;
        font-size: 1.6rem;
        color: theme-color(primary);
        font-weight: 700;
        margin-bottom: 30px;

        span {
           font-size: 2rem;
           font-weight: bold;
        }
    }

    label {
        margin-bottom: 10px;
        font-size: 16px;

        &.or {
            display: block;
            position: relative;
            
            &::before, &::after {
                content: "";
                display: inline-block;
                width: calc(50% - 40px);
                height: 1px;
                background: lighten(theme-color(primary), 60%);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }

    hr {
        border-top: rgba(theme-color(primary), 0.3) solid 1px;
        margin-bottom: 15px;
    }

    .btn-submit {
        display: block;
        width: 100%;
        background: theme-color(primary);
        color: theme-color(white);
    }

    a {
        color: theme-color(primary) !important;
    }

    #spinner {
        text-align: center;
        width: 100%;
        border-radius: 5px;
        background: theme-color(facebook);

        a {
            color: theme-color(white) !important;
            padding: 15px 20px;
            display: block;

            i {
                color: theme-color(white) !important;
            }
        }
    }
}