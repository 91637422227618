.menu-accordeon {
    .title-accordeon {
        display: block;
        width: 100%;
        border: none;
        padding: 15px 0;
        border-bottom: #ddd solid 1px;
        background: none;
        text-align: left;
        text-transform: uppercase;
        font-weight: bold;
        position: relative;

        &::after {
            content: "";
            background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUwIDUwIiBoZWlnaHQ9IjUwcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MCA1MCIgd2lkdGg9IjUwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxyZWN0IGZpbGw9Im5vbmUiIGhlaWdodD0iNTAiIHdpZHRoPSI1MCIvPjxwb2x5Z29uIHBvaW50cz0iMTUsMi43NSAxMi45MTQsNC44MzYgMzMuMDc4LDI1IDEyLjkxNCw0NS4xNjQgMTUsNDcuMjUgMzcuMjUsMjUgIi8+PC9zdmc+');
            width: 15px;
            height: 15px;
            background-size: 15px 15px;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
            transition: all ease 0.3s;
        }

        &.open {
            &::after {
                transform: rotate(90deg);
            }

            & + .content-accordeon {
                height: auto;
                overflow: visible;
                padding: 15px 0;
            }
        }
    }
    .content-accordeon {
        height: 0;
        overflow: hidden;

        li:not(:last-child) {
            margin-bottom: 10px;
        }

        a {
            font-weight: bold;
            transition: all ease 0.3s;
            color: theme-color(primary);

            &:hover {
                opacity: 0.4;
            }
        }
    }

}