.header-account {
	padding: 150px 0 60px 0;
    background: #f4f4f5;
    
    .info-user {
        h1{
            font-size: 19px;
            color: theme-color(primary);
            font-weight: 300;
            text-transform: uppercase;
        }
        p {
            font-size: 14px;
            color: theme-color(light);
        }
    }
    .aide {
        padding-top: 10px;

        h2 {
            font-size: 21px;
            color: theme-color(primary);
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        h4 {
            font-size: 14px;
            color: theme-color(primary);

            i {
                margin-right: 7px;
            }
        }
        p {
            font-size: 14px;
            color: theme-color(light);
            margin-bottom: 15px;
            padding-left: 15px;
        }
    }
}

.contenu_user {
	padding-top: 45px;
    padding-bottom: 100px;
    
    .left-sidebar {
        padding-right: 0;
        border-right: 1px solid theme-color(light);

        .menu_user {
            margin-bottom: 60px;

            .nav-stacked > li + li {
                margin-top: 25px;
                margin-left: 0;
            }
            .nav-stacked > li a {
                border-radius: 0;
                background: theme-color(primary);
                color: theme-color(white);
                font-size: 14px;
                font-weight: normal;
                padding-left: 20px;
                text-transform: uppercase;
                transition: all 0.2s ease-out;
            }
            .nav-stacked > li a i {
                margin-right: 7px;
            }
            .nav-stacked > li a i:nth-child(2) {
                margin-left: -7px;
            }

            .nav-pills > li > a.active, 
            .menu_user .nav-pills > li > a.active:focus, 
            .menu_user .nav-pills > li > a.active:hover,
            .menu_user .nav-pills > li > a:focus, 
            .menu_user .nav-pills > li > a:hover{
                background: theme-color(secondary);
                color: theme-color(white);
            }
        }

        .logo-compte {
            text-align: center;
            
            img {
                margin: 0 auto;
            }
        }
    }
}


.informations { 
    p.form-info {
        font-size: 14px;
        color: theme-color(primary);
        margin-bottom: 20px;
        padding-top: 20px;
    }
    label {
        font-size: 14px;
        color: theme-color(primary);
        font-weight: 300;
        margin-bottom: 5px;
    }
    .form-control {
        border-radius: 0;
        border:1px solid theme-color(light);
        font-size: 14px;
        color: theme-color(primary);
        margin-bottom: 15px;
    }
    select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }
    .btn-golf {
        border-radius: 0;
        border:1px solid theme-color(secondary);
        background: theme-color(secondary);
        color: theme-color(white);
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        transition: all 0.3s ease-out;

        &:hover {
            border:1px solid theme-color(light);
            background: theme-color(light);
        }
    }
    .btn-golf-gris {
        border-radius: 0;
        border:1px solid theme-color(light);
        background: theme-color(light);
        color: theme-color(white);
        text-transform: uppercase;
        font-weight: normal;
        font-size: 14px;
        margin-top: 20px;
        transition: all 0.3s ease-out;

        &:hover{
            border:1px solid theme-color(secondary);
            background: theme-color(secondary);
        }
    }
    .historique {
        background: theme-color(white);
        border:0;
        padding-bottom: 50px;

        &.archive {
            min-height: 600px;
        }
    }
    .table-historique {
        width: 100%;

        thead {
            border-bottom: 1px solid theme-color(white);

            th {
                padding: 0;
                padding-top: 20px;
                padding-bottom: 7px;
                text-transform: uppercase;
                font-weight: 300;
                font-size: 14px;
                color: theme-color(primary);
            }
        }
        tbody td {
            color: theme-color(primary);
            font-weight: 300;
            font-size: 14px;
        }
        .date {
            width: 20%;
        }
        .produit {
            width: 40%;
            padding-right: 25px;
        }
        .prix {
            width: 20%;
        }
        .status {
            width: 20%;
        }
        .status {
            width: 20%;
        }
        .objet {
            width: 25%;
            padding-right: 20px;
        }
        .detail {
            width: 55%;
            padding-top: 45px;
        }
        .name {
            width: 45%;
        }
        .email {
            width: 45%;
        }
        .stat {
            width: 10%;
        }
        th.detail {
            padding-top: 20px;
        }
        td.produit {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        td.name, td.email, td.stat {
            padding-top: 30px;
        }
    }
    h2.title-parainnage {
        position: relative;
        font-weight: 300;
        color: theme-color(primary);
        font-size: 21px;
        margin-bottom: 50px;
    }
    h2.title-parainnage.points {
        margin-bottom: 5px;
    }
    h2.title-parainnage .orange {
        color: theme-color(secondary);
    }
    h2.title-parainnage::after {
        content: ' ';
        width: 50px;
        height: 1px;
        background: theme-color(primary);
        position: absolute;
        bottom: -15px;
        left: 0;
    }
    p.points-info {
        color: theme-color(primary);
    }
    .form-parainnage {
        margin-bottom: 55px;
    }
    .convert {
        padding-right: 0;
    }
    .convert p {
        color: theme-color(primary);
        font-size: 13px;
        line-height: 35px;
    }
}