%article-carousel {
    @extend %transition;
    position: relative;
    clip-path: polygon(calc(100% - 45px) 0, 100% 45px, 100% 100%, 0 100%, 0 0);
    margin-bottom: 30px;
    overflow: hidden;

    &::before {
        @extend %transition;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 45px 0 0 45px;
        border-color: transparent transparent transparent theme-color(secondary);
        position: absolute;
        bottom: -45px;
        left: -45px;
        z-index: 3;
    }

    &:hover {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 0);

        &::before {
            bottom: 0;
            left: 0;
        }
    }

    header a {
        display: block;
        height: 255px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    section { 
        padding: 0;

        h3 {
            margin-bottom: 0;

            a {
                @extend %transition;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, rgba(theme-color(primary),0.8) 60%, rgba(theme-color(primary), 0) 90%);
                font-weight: 400;
                color: theme-color(white);
                font-size: 18px;
                text-decoration: none;

                &:hover {
                    background: rgba(theme-color(primary),0.8);

                    span {
                        @extend :hover;
                    }
                }

                & > span {
                    position :absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 3;
                    padding: 30px;

                    &::before {
                        content: attr(data-prefix);
                        font-style: italic;
                        font-weight: 600;
                        color: lighten(saturate(theme-color(secondary),30%),18%);
                        display: block;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }
                }

                span span {
                    font-weight: 600;
                    font-size: 16px;
                    margin-top: 20px;
                    display: block;

                    &::after {
                        @extend %transition;
                        content: attr(data-sufix);
                        margin-left: 8px;
                        padding-bottom: 5px;
                        border-bottom: theme-color(white) solid 1px;
                    }

                    &:hover {
                        &::after {
                            border-color: rgba(theme-color(white),0.6);
                            margin-left: 12px;
                        }
                    }
                }
            }
        }
    }
}