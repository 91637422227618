body.index {
    @import "map-home";
    @import "slide-home";
    @import "parts/encart-accueil-legacy";

    main section {
        font-size: 16px;
        padding-top: 30px;
        padding-bottom: 30px;

        h3 {
            margin-bottom: 30px;
            font-weight: bold;
        }

        .h2, h2 {
            text-align: left;
            font-size: 34px;
            font-weight: normal;
            margin-bottom: 40px;

            @media(max-width: 767px) {
                font-size: 20px;
            }

            span {
                font-weight: bold;
            }

            h2 {
                display: inline-block;
                margin-bottom: 0;
            }

            .dropdown, select, form {
                font-weight: bold;
                display: inline-block;
                appearance: none;
                border: none;

                option {
                    font-size: 1.8rem;
                }
            }
            select {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3c!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 18 10' style='enable-background:new 0 0 18 10;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill:%23263C75;%7d %3c/style%3e%3cpath class='st0' d='M9,10c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L9,7.6l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C9.5,9.9,9.3,10,9,10z'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: 0.8em 0.4em;
                background-position: calc(100% - 15px) center;
                padding-right: 1.5em;
            }
        }

        &#home-destinations {
            padding-bottom: 30px;
            background: url(../images/globe-earth.svg) no-repeat;
            background-size: 260px 260px;
            background-position: calc(50% - 130px) top;

            @media(max-width: 991px) {
                padding-top: 120px;
            }

            @media(min-width: 992px) {
                padding-top: 125px;
            }
        }

        &#home-destinations, &#travel-golf, &#home-featured, &#home-themes {
            article {
                @extend %article-carousel;
            }
        }

        &#home-themes {
            background: lighten(theme-color(primary), 75%);

            article {
                header a {
                    width: 165px;
                    height: 165px;
                }
                section h3 a span {
                    padding: 15px !important;

                    span {
                        margin-top: 0 !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }
                }
            }
        }

        &#travel-golf {
            background: lighten(theme-color(light), 35%);
        }

        &#home-featured {
            background: desaturate(lighten(theme-color(secondary),33%),25%);

            .content {
                margin-bottom: 40px;
            }

            .social-link {
                li {
                    a {
                        display: inline-block;
                        width: 100%;
                        padding: 15px 20px;
                        color: theme-color(white);
                        text-transform: uppercase;
                        font-weight: bold;
                        vertical-align: middle;
                        @extend %transition;

                        &:hover {
                            background: rgba(theme-color(black), 0.3);
                            text-decoration: none;
                        }

                        i {
                            font-size: 2.5rem;
                            vertical-align: middle;
                            margin-right: 8px;
                        }
                    }
                    &:nth-child(1) {
                        background: theme-color(primary);
                    }
                    &:nth-child(2) {
                        background: lighten(theme-color(primary), 20%);
                    }
                    &:nth-child(3) {
                        background: lighten(theme-color(primary), 30%);
                    }
                    &:nth-child(4) {
                        background: lighten(theme-color(primary), 35%);
                    }
                    &:nth-child(5) {
                        background: lighten(theme-color(primary), 45%);
                    }
                }
            }

            .quote {
                .author {
                    border-left: rgba(theme-color(primary),0.2) solid 1px;
                    margin-top: 20px;
                    padding:  15px 0 15px 15px;
        
                    .author-name {
                        font-weight: 900;
                        font-size: 16px;
                    }
                    .author-job {
                        font-style: italic;
                        color: lighten(theme-color(primary),35%);
                    }
                }
            }

            #reinsurance {
                padding-top: 30px;

                div[class^="col-"] {
                    .item {
                        color: theme-color(white);
                        padding: 20px;
                        text-align: center;
                        font-weight: bold;

                        i {
                            font-size: 3rem;
                            display: block;
                            margin: 0 auto 10px auto;
                        }
                    }
                    &:nth-child(odd) .item {
                        background: lighten(theme-color(primary), 10%);
                    }
                    &:nth-child(even) .item {
                        background: lighten(theme-color(primary), 25%);
                    }
                }
            }
        }

        &#home-discovery {
            padding-bottom: 0;

            article {
                height: 660px;
                position: relative;
                background-size: cover;

                @media(max-width:767px) {
                    height: 450px;
                }

                a {
                    @extend %transition;
                    box-shadow: 0px 0px 0 0px transparent;
                    border: transparent solid 1px;
                    text-decoration: none;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    padding: 30px;
                    background: rgba(theme-color(primary),0.8);
                    text-align: center;
                    color: theme-color(white);

                    &:hover {
                        box-shadow: 0px 0px 15px 0px rgba(theme-color(primary),0.6);
                        border-color: theme-color(white);

                        .button, .city {
                            @extend :hover;
                        }
                    }

                    div {
                        position: absolute;

                        @media(min-width: 992px) {
                            top: 50%;
                            left: 50%;
                            z-index: 3;
                            transform: translate(-50%, -50%);
                        }
                        @media(max-width: 991px) {
                            width: 100%;
                            top: 50%;
                            left: 0;
                            z-index: 3;
                            transform: translateY(-50%);
                            padding:30px;   
                        }

                        h2 {
                            margin: 0;
                        }

                        i {
                            font-size: 82px;
                            margin-bottom: 8px;
                        }

                        .city {
                            @extend %transition;
                            font-weight: 900;
                            font-size: 140px;
                            line-height: 1;
                            margin-bottom: 40px;
                            display: inline-block;
                            position: relative;

                            @media(max-width: 767px) {
                                font-size: 36px;
                            }

                            @media(min-width: 768px) and (max-width:991px) {
                                font-size: 47px;
                            }

                            &::before, &::after {
                                @extend %transition;
                                position: absolute;
                                top: 0;
                                opacity: 0;
                            }

                            &::before {
                                content: "[";
                                display: inline-block;
                                left: 50%;
                            }
                            &::after {
                                content: "]";
                                display: inline-block;
                                right: 50%;
                            }

                            &:hover {
                                color: lighten(saturate(theme-color(secondary),30%),18%);

                                &::before {
                                    left: -15%;
                                    opacity: 1;
                                }
                                &::after {
                                    right: -15%;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        &#next-travel {
            padding-top: 0;

            @media (min-width: 1199px) {
                div[class^="col"]:nth-child(odd) {
                    clear: both;
                }
            }
        }
    }

    #home-seo {
        font-size: 15px;
        color: theme-color(primary);
        line-height: 1.6;

        a {
            color: theme-color(primary);
            text-decoration: underline;
        }

        h3 {
            margin-bottom: 30px;

            span {
                font-weight: 900;
            }
        }

        p {
            margin-bottom: 15px;
        }
    }

    .item-home {
        position: relative;
        margin-bottom: 30px;
        background-color: theme-color(primary);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        a {
            padding: 30px;
            min-height: 300px;
            display: block;
            background: rgba(theme-color(primary), 0);
            background: linear-gradient(0deg, rgba(theme-color(primary), 0) 0%, rgba(theme-color(primary), 1) 100%);
            color: theme-color(white);
            font-family: 'Raleway';
            text-decoration: none;
            @extend %transition;

            &:hover {
                background: rgba(theme-color(primary), 0.8);

                section, footer {
                    opacity: 1;
                }
            }

            header {
                font-size: 18px;
                margin-bottom: 15px;
                font-weight: bold;
                position: relative;
    
                img {
                    display: none;
                }
            }
    
            section {
                padding: 0;
                font-weight: 600;
                opacity: 0;
                @extend %transition;
    
                .description {
                    padding: 0;
                }
            }
            footer {
                padding-top: 15px;
                opacity: 0;
                @extend %transition;

                .btn {
                    background: theme-color(secondary);
                    padding: 10px;
                }
            }
        }

    }
}