body.details-produits, body.interest-produits {

    #breadcrumb-content {
        padding-top: 0 !important;
        
        .container {
            padding: 0;
            width: 100%;

            .breadcrumb {
                padding-top: 0 !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

    @media (max-width: 480px) {
        .tarif {
            display: block !important;
            float: none !important;
            margin-top: 15px;
            text-align: left !important;
        }
    }

    .tarif {
        color: theme-color(primary);
        text-align: right;
        float: right;
    }

    #btn-booking {
        position: fixed;
        bottom: 30px;
        left: 30px;
        z-index: 180;
        width: calc(100% - 60px);

        &.hidden {
            display: none !important;
        }
    }

    .content-flex {
        @media (min-width: 1199px) {
            display: flex;
            flex-wrap: wrap;
            clear: both;

            & > div[class^="col-"] {
                float: none;
            }

            & > .col-md-9 {
                max-width: 75%;
                flex: 0 0 75%;
            }
            & > .col-md-3 {
                max-width: 25%;
                flex: 0 0 25%;
            }
        }

        .description {
            h1, h2 {
                font-weight: bold;
            }
        }

        .reservation.reservationAbsolute11 {
            position: static !important;
        }

        #nt-example1-container {
            overflow-y: auto;
            height: 1000px;
            margin-bottom: 30px;

            .control-last {
                display: none;
            }
        }

        .sidebar {
            .btn {
                white-space: initial !important;
            }
        }

        .fancybox-thumb {
            i[class^="fa"] {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 3;
                color: theme-color(white);
                width: 50px;
                height: 50px;
                text-align: center;
                line-height: 45px;
                background: rgba(theme-color(primary), 1);
                display: block;
            }
        }

        @import "parts/select-date";
    }

    .section-content {
        background: lighten(theme-color(light), 35%);
        margin-bottom: 30px;
        padding: 20px;
    }

    #nav-content-product {
        margin-bottom: 30px;
        width: 100%;
        position: relative;

        .nav-box {
            @extend %transition;
        }

        @media (min-width: 768px) {
            &.fixed .nav-box {
                background: theme-color(white);
                border-radius: 5px;
                box-shadow: 0px 0px 10px 0px rgba(theme-color(primary),0.3), inset 0px -4px 0px 0px lighten(theme-color(primary),50%);
                padding: 20px;
                position: fixed;
                top: 130px;
                left: 50%;
                z-index: 15;
                transform: translateX(-50%);
            }
        }
    }

    @import "parts/nav-pills";
}

body.details-produits {
    #carousel-home {
        margin-top: 0;

        .carousel-caption {
            background: none;
            box-shadow: none;
            padding: 15px;
            height: auto !important;
            color: theme-color(white);
            /*top: 50% !important;*/
            bottom: 30px;
            left: 50% !important;
            transform: translateX(-50%) !important;
            /*transform: translate(-50%, -50%) !important;*/
            background: rgba(theme-color(primary), 0.6) !important;
            /*position: static !important;
            transform: none !important;
            margin: 15px auto 0 auto;
            height: auto !important;*/
        }
    }
    .fb-share-button.fb_iframe_widget {
        transform: scale(1.25);
        margin: 5px 10px 0 0;
    }
    .btn_panier_actif,
    .btn_panier_inactif {
        display: block !important;
        width: 100% !important;
        height: auto !important;
        padding: 10px !important;
    }
}