.btn {
    white-space: initial !important;
}

.button {
    @extend %transition;
    border-radius: 5px !important;
    padding: 15px 80px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    display: inline-block;

    @media(max-width: 991px) {
        padding: 15px 30px;
        display: block;
    }

    &:hover {
        text-decoration: none;
    }

    @each $name, $color in $theme-colors {
        &.button-#{$name} {
            background: $color;
            color: theme-color(white);
            box-shadow: inset 0px -4px 0px 0px rgba(theme-color(primary),0.3);

            &:hover {
                box-shadow: inset 0px -2px 0px 0px rgba(theme-color(primary),0.3);
                transform: translateY(2px);
                background: $color;
            }
        }
    }
}
.btn {
    @extend .button;
    padding: 15px 20px;

    &.btn-golf {
        @extend .button-secondary;
    }
}

body.commande {
    .btn:not(.btn-lg), .button:not(.btn-lg) {
        padding-top: 7px;
        padding-bottom: 7px;
        font-size: 14px;
    }
}