.box {
    background: theme-color(white);
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(theme-color(primary),0.3), inset 0px -4px 0px 0px lighten(theme-color(primary),50%);
    padding: 30px;
}

#flypage #last-avis {
    height: auto !important;
}

.separateur-paragraphe {
    height: 1px;
    background: none;
    border-bottom: #ccc solid 1px;
    clear: both;
    margin: 20px 0;
}


body {
	color: theme-color(primary) !important;
	overflow-x: hidden;
	font-family: 'Raleway' !important;
    font-weight: 300;
    font-style: normal;
    background: theme-color(white) !important;
    
    &.show-account {
        .login-form {
            display: block;
        }

        .top-header {
            position: relative;
            z-index: 200;
        }

        #header {
            .overlay {
                display: block;
                top: 0;
                left: 0;
                z-index: 190;
                width: 100vw;
                height: 100vh;
                position: absolute;
                background: rgba(theme-color(primary), 0.6);
            }
        }
    }
}

/* margin custom */
.mt15 {
    margin-top:15px;
}
.mt30 {
    margin-top:30px;
}
.mb15 { 
    margin-bottom:15px;
}
.mb30 { 
    margin-bottom:30px;
}

.on-sale-price {
    text-decoration: line-through;
}

#pages:not(.with-slider) {
    padding: 120px 0 60px 0;
}