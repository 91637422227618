.emebed-carousel {
    &::before {
        content: '';
        display: block;
        padding-top: 32.5%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}