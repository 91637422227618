.top-header{
	background: theme-color(primary);
    padding: 10px 0;
    
    .row {
        padding: 0;
        line-height: 16px;

        a {
            height: auto;
            background: none;
        
        }
    }

    h1, .h1 {
        color: theme-color(white);
        font-size: 12px;
        padding: 0;
        margin: 0;

        @media(max-width:991px) {
            display: none;
        }

        span {
            font-weight: bold;
            font-size: 16px;
        }
    }

    ul {
        text-align: right;
        line-height: 1;

        > li {
            vertical-align: middle;
            padding: 0;

            @media(max-width:768px) {
                display: inline-block;
                width: auto !important;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }

            .login-form {
                z-index: 200;
                background: theme-color(white) !important;
                top: calc(100% + 13px) !important;
                right: 0 !important;
            }

            a, .btn {
                font-size: 13px;
                text-decoration:none;
                text-decoration: none;
                color: #fff;
                font-weight: bold;
                font-family: 'Raleway';
                text-transform: uppercase;

                &.deconnexion {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }

                i {
                    color:#999;
                    font-size: 15px;
                    display: inline-block;
                    margin-right: 3px;
                }

                span {
                    color: lighten(saturate(theme-color(secondary),30%),18%);
                }

                img {
                    margin-right: 5px;
                    margin-top: -7px;
                }
            }
        }
    }
}