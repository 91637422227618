.select-date {
    padding: 20px 25px;

    a {
        text-decoration: none;
        @extend %transition;

        &:hover {
            opacity: 0.6;
        }
    }

    & > .title {
        background: theme-color(primary);
        color: theme-color(white);
        padding: 8px;
        text-align: center;
        position: relative;
        margin-bottom: 20px;

        span {
            font-weight: bold;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: theme-color(primary) transparent transparent transparent;
        }
    }

    .item {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: theme-color(light) solid 2px;

        .label {
            margin-bottom: 10px;
            display: inline-block;
            font-size: 14px;
            border: theme-color(white) solid 1px;
            padding: .3em .6em !important;
        }

        .date {
            font-size: 1.6rem;
        }

        .date, .delay {
            span {
                font-weight: bold;
            }
        }
        .price-content {
            font-style: italic;
            padding-top: 10px;
            padding-bottom: 10px;

            .price {
                font-style: normal;
                font-size: 2rem;
                font-weight: bold;
            }
        }

        .add-like {
            margin-top: 10px;
            margin-bottom: 10px;

            a {
                margin-top: 5px;
            }
        }

        .detail a {
            font-weight: bold;
            display: block;
            color: theme-color(primary);

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    .informations {
        padding-left: 0;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
        .title {
            font-weight: bold;
            font-size: 1.6rem;
            margin-bottom: 5px;
        }

        a {
            color: theme-color(secondary);
            font-weight: bold;
        }

        ol, ul {
            padding: 0;

            li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }
        ol {
            counter-reset: li;

            li {
                counter-increment: li;

                &::before {
                    content: counter(li);
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    line-height: 18px;
                    background: theme-color(secondary);
                    display: inline-block;
                    color: theme-color(white);
                    font-weight: bold;
                    margin-right: 5px;
                    text-align: center;
                }
            }
        }
    }
}