#footer {
    background: theme-color(primary);
    color: theme-color(white);
    font-size: 16px;

    a {
        color: theme-color(white);
        z-index: 1 !important;
        @extend %transition;
        height: auto;
        background: none;
        display: inline-block;

        &:hover {
            opacity: 0.6;
            text-decoration: none;
        }
    }

    @media(min-width:768px) {
        div[class^="col-"] {
            &:not(:last-child) {
                border-right: lighten(theme-color(primary),10%) solid 1px;
            }
            &:not(:first-child) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    @media(max-width:767px) {
        div[class^="col-"] {
            margin-bottom: 15px;
        }
    }

    @media(max-width:767px) {
        .title {
            cursor: pointer;
            position: relative;

            &::after {
                content: "+";
                font-size: 36px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                line-height: 1;
            }

            &.open::after {
                content: "-";
            }
        }
        .block-footer {
            display: none;

            &.open {
                display: block;
            }
        }
    }

    .content-footer {
        padding-top: 60px;
        padding-bottom: 60px;

        @media(max-width:767px) {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .title {
            color: lighten(saturate(theme-color(secondary),30%),18%);
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;

            @media(max-width:767px) {
                margin-bottom: 15px;
            }

            a {
                color: lighten(saturate(theme-color(secondary),30%),18%);
            }
        }

        ul ul {
            font-size: 14px;
            margin-bottom: 15px;
        }

        .address {
            margin-bottom: 30px;

            i {
                width: 25px;
            }

            small {
                font-style: italic;
                opacity: 0.6;
            }
            .hours {
                margin-top: 15px;
            }
        }

        .social-footer {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 15px;

                    @media(min-width:768px) and (max-width:991px) {
                        margin-right: 5px;
                    }
                }

                a {
                    display: block;
                    background: lighten(theme-color(primary),50%);
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    color: theme-color(primary);
                    font-size: 30px;
                    border-radius: 100%;

                    @media(min-width:768px) and (max-width:991px) {
                        width: 30px;
                        height: 30px;
                        font-size: 20px;
                    }

                    i {
                        line-height: 50px;

                        @media(min-width:768px) and (max-width:991px) {
                            line-height: 30px;
                        }
                    }

                    @each $name, $color in $theme-colors {
                        &.social-#{$name}:hover{
                            color: theme-color(white);
                            background: $color;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .copyright {
        padding-top: 20px;
        padding-bottom: 20px;
        background: lighten(theme-color(primary),8%);
        font-size: 13px;
        font-weight: 600;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                display: inline-block;
            }

            &.links-copyright {
                @media(min-width:768px) {
                    float: left;
                }

                li:not(:last-child) {
                    padding-right: 8px;
                    margin-right: 8px;
                    border-right: rgba(theme-color(white),0.3) solid 1px;
                }
            }
            &.links-payment {
                @media(min-width:768px) {
                    float: right;
                }
                @media(max-width:767px) {
                    margin-top: 15px;
                }
            }
        }
    }
}