#supersized{
	display:none !important;
}

*, html, div, ul, li, h1, h2, h3, h4, h5, h6, form, label, fieldset, input, th, td, hr, p, ol, dl, dt, dd {
    margin:0;
    padding:0;
}
fieldset, img, hr {
    border:0;
}
ol, ul, li { 
    list-style:none;
}
a img {
    border:0;
}
*:focus {
    outline: none !important;
}