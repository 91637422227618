
/* Page d'accueil
/* --------------------------------------------------------------------------------------------------------------- */
.espace-membre{
	width: 160px;
	height: 25px;
	position: absolute;
	top: 40px;
	left:100px;
	z-index: 9;
}
.espace-membre .btn-membre{
	width: 100%;
	height: 25px;
	background:rgba(0,0,0,0.5);
	line-height: 24px;
	font-size: 13px;
	font-family: 'Raleway';
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
	padding: 0;
	-webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.espace-membre .btn-membre.deconnexion{
	margin-top: 10px;
    z-index: 9;
    position: absolute;
    top: 25px;
}
.espace-membre .btn-membre i{
	font-size: 15px;
	font-weight: 300;
	margin-left: 4px
}
.espace-membre .btn-membre:hover,
.espace-membre .btn-membre:focus{
	background:#000;
}

.destination{
	position: absolute;
	top:30%;
	left: 50%;
	display: none;
}
.btn-orange{
	background: #FC533F;
    color: #fff;
    border: 0;
    border-radius: 0;
    font-size: 15px;
    -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.destination .dropdown.open .btn-orange,
.btn-orange:hover{
	background: #F5270F;
    color: #fff;
}
.destination .dropdown .dropdown-menu{
	width: 208px;
	border-radius: 0;
}
.containt-home{
	padding-top: 55px;
	padding-bottom: 55px;
}
.one-item-menu{
	margin-bottom: 40px;
}
.one-item-menu h2{
	font-size: 18px;
	font-family: 'Raleway';
	font-weight: normal;
	text-transform: uppercase;
	margin-bottom: 25px;
	position: relative;
}
.one-item-menu h2:after{
	content: ' ';
    width: 20px;
    height: 2px;
    background: #333333;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.one-item-menu h2 a{
	color: #333;
}
.one-item-menu ul>li>a{
	text-decoration: none;
	font-size: 14px;
	color: #333;
}
.one-item-menu ul>li>a:hover{
	text-decoration: underline;
}
footer{
	position: relative;
}

.form-contact{
	width: 230px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 50px;
}
.head-contact{
	width: 100%;
	height: 30px;
	background: #202020;
	color: #fff;
	border-radius: 5px 5px 0 0;
	cursor: pointer;
}
.head-contact p{
	text-align: center;
	font-family: 'Raleway';
	font-size: 13px;
	font-weight: bold;
	line-height: 30px;
	text-transform: uppercase;
}
.head-contact p i{
	font-size: 15px;
}
.head-contact p i.up{
	display: inline-block;
}
.head-contact p i.down{
	display: none;
}
.body-contact{
	width: 100%;
	height:auto;
	min-height: 200px;
	background: #eceaea;
	color: #fff;
	display: none;
}
/* Page sejour
/* --------------------------------------------------------------------------------------------------------------- */
.contenu{
	padding-bottom: 65px;
}
.search .nav-tabs {
    display: inline-block;
}
.search .nav-tabs>li>a{
   margin-right: 0;
   background:#f7f7f6;
   border-radius: 0;
   color: #000000;
   text-decoration: none;
   font-size: 15px;
   text-transform: uppercase;
   font-weight: normal;
   margin-bottom: 1px;
}
.search .nav-tabs>li{
    border-bottom: 1px solid #acacac;
}
.search .nav-tabs>li a:hover{
	border:1px solid #f7f7f6;
}
.search .nav-tabs>li.active{
	border-bottom: 3px solid #000000;
}
.search .nav-tabs>li.active>a, 
.search .nav-tabs>li.active>a:hover{
   border:0;
   background:#f7f7f6;
}
.search .tab-pane{
	background:#f7f7f6;
	min-height: 140px;
	padding: 25px 0;
	padding-bottom: 0px;
}
.search .tab-pane form{
	display: inline-block;
    width: 100%;
}
.search .one-search{
	margin-bottom: 20px;
}
.search select,
.custom-select{
	border:1px solid #9b9b9a;
	background:url('../images/arrows.png') no-repeat 95% #f7f7f6;
	border-radius: 0;
	 -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
	width: 100%;
}
.search select::-ms-expand ,
.custom-select{
    display: none;
}
.search select:focus,
.custom-select{
	box-shadow: none;
	border:1px solid #9b9b9a;
}
.btn-black{
	background:#333333;
	border-radius: 0;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: normal;
	font-family: 'Raleway';
	height: auto !important;
}
#articles-col-left a {
    color: #333;
    font-weight: bold;
}
.btn-black:hover,
.btn-black:focus{
	background:#000;
	color: #fff;
}
.one-ckeck{
	padding-top: 9px;
}
.check-golf{
	width: 15px;
	height: 15px;
	background:url('../images/not-check.png') no-repeat;
	float: left;
	margin-right: 11px;
	margin-top: 2px;
}
.check-golf .check-input{
	opacity: 0;
	width: 15px;
	height: 15px;
	margin:0;
}
.check-golf.active{
	background:url('../images/check.png') no-repeat;
}
.description {
	font-family: 'Raleway';
}
.description h2{
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 22px;
}
.description .title-suparator{
	width: 50px;
	height: 3px;
	background:#333333;
	margin: 0 auto;
	margin-bottom: 45px;
	margin-top: 25px;
	margin-bottom: 40px;
}
.description p{
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
    margin-bottom: 22px;
    font-weight: 200;
}
.description h3{
	margin-top: 35px;
	margin-bottom: 30px;
	font-weight: bold !important
}

.description a.suite{
	text-decoration: none;
    font-weight: bold;
    color: #000000;
}
.articles{
	margin-top: 20px;
}
.one-article{
	-webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2); 
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2); 
    margin-bottom: 30px;      
}
.one-article .img-article{
	position: relative;
}
.one-article .img-article img{
	width: 100%;
}
.one-article .img-article .complet{
	position: absolute;
	bottom: 110px;
	left: 0;
	display: none;
}
.one-article.article-complet .img-article .complet{
	display: block;
}
.one-article.article-complet a{
	text-decoration:none;
}
.one-article.article-complet a:hover{
	text-decoration:none;
}
.one-article.article-complet h6, .one-article.article-complet i{
	color:#747474;
}
.one-article .img-article .bottom-img{
	width: 100%;
	/*height: 110px;*/
	height: 85px;
	background:rgba(255,255,255,0.8);
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 15px 25px;
}
.one-article .img-article .bottom-img.bottom-img-carte{
	height: 110px;
}
.one-article .img-article .bottom-img .entrainement{
	float: left;
	width: 100%;
}
.one-article .img-article .bottom-img .entrainement p{
	font-size: 16px;
	font-weight: normal;
	color: #000000;
	font-family: 'Raleway';
}
.one-article .img-article .bottom-img .prix{
	float: right;
	width: 30%;
}
.one-article .img-article .bottom-img .prix p{
	font-size: 13px;
	color: #000000;
}
.one-article .img-article .bottom-img .prix p>span.price{
	font-size: 20px;
	font-weight: bold;
}
.one-article .img-article .bottom-img .prix p>span.person{
	font-size: 12px;
	font-weight: bold;
}
.bottom-article{
	padding: 25px;
	padding-bottom: 20px;
	padding-top: 15px;
	display: inline-block;
    width: 100%;
        min-height: 90px;
        position:relative;
}
.bottom-article p{
	color: #000000;
	font-size: 16px;
}
.bottom-article .right-bottom p{
	font-size: 15px;
	text-align: right;
}
 .bottom-article p.red{
	color: #fc533f;
	font-size: 13px;
	display: none;
}
.one-article.article-complet .bottom-article p.red{
	display: block;
}

.pagin-page{
	display: inline-block;
	width: 100%;
	margin-top: 10px;
    margin-bottom: 30px;
}

.pagination{
	margin: 0;
	border-radius: 0;
}
.pagination>li>a, 
.pagination>li>span {
    color: #999999;
    background-color: #f2f1f0;
    border: 0;
    border-radius: 0;
    text-decoration: none;
    margin-right: 5px;
}
.pagination>.active>a, 
.pagination>.active>a:focus, 
.pagination>.active>a:hover, 
.pagination>.active>span, 
.pagination>.active>span:focus, 
.pagination>.active>span:hover
.pagination>li>a:focus, 
.pagination>li>a:hover, 
.pagination>li>span:focus, 
.pagination>li>span:hover{
	color: #fff;
    background-color: #333333;
    border: 0;
    border-radius: 0;
}
.pagination>li:first-child>a, 
.pagination>li:first-child>span,
.pagination>li:last-child>a, 
.pagination>li:last-child>span{
	border-radius: 0;
}
.pagin-page .nbr-articles{
	text-align: right;
}
/* Page Coach
/* --------------------------------------------------------------------------------------------------------------- */
.description-coach{
	padding-top: 0;
	padding-top: 29px;
    padding-bottom: 18px;
}
.description-coach a {
	color : #FFF !important;
}
.icon-block {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #333333;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
    font-size: 30px;
}
.icon-block em {
    line-height: 100px;
    margin-left: 11px;
}
.reserv-coach{
	background: #f7f7f7;
    padding: 15px 20px;
    margin-bottom: 50px;
}
.reserv-coach a{
	color:#FFF !important;
}
.three-blocks {
    display: inline-block;
    width: 100%;
    margin-bottom:20px;
}
.reserv-coach h2 {
    color: theme-color(secondary);
    font-size: 20px;
    position: relative;
    margin-bottom: 60px;
}
.one-info-res p .black {
    font-size: 15px;
    color: #fff;
    background: #343434;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    line-height: 30px;
    text-align: center;
    float: left;
    margin-right: 10px;
}
.top-temoignages h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 22px;
}
.top-temoignages p {
    font-size: 13px;
    color: #333333;
    margin-bottom: 25px;
    font-weight: 200;
}
.desc-temoignage {
    position: relative;
    background: #f7f7f7;
    padding: 35px 15px;
    margin-bottom: 30px;
}
.desc-temoignage:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(247, 247, 247, 0);
    border-top-color: #f7f7f7;
    border-width: 10px;
    margin-left: -10px;
}
.author-temoignage {
    text-align: center;
}
.author-temoignage h3 {
    text-transform: uppercase;
    font-size: 14px;
}
.author-temoignage p {
    color: #6E6C61;
}
.list-temoignages {
	display: inline-block;
	margin-bottom:10px;
}
.title-suparator {
    width: 50px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    margin-bottom: 45px;
    margin-top: 20px;
}
.reserv-coach h2:after {
    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.advanced{
	margin-bottom: 65px;
	width: 100%;
    display: inline-block;
}
.advanced-search{
	width: 100%;
	background:#f0f0ef;
	padding: 30px;
	padding-bottom: 0;
	display: inline-block;
}
.advanced-search select{
	margin-bottom: 20px;
}
.advanced-search h2{
	margin-bottom: 25px;
	color: #000000;
	font-size: 16px;
	margin-top: 10px;
}
.advanced-search ul>li{
	margin-bottom: 15px;
}
.cerfit{
	float: left;
	width: 24px;
	height: 25px;
	margin-right: 5px;
}
.advanced-search .cerfit{
	margin-top: -3px;
}
.advanced-search .mrp{
	margin-bottom: 15px;
}
.advanced-search .stagaires{
	margin-top: 30px;
	margin-bottom: 10px;
}
.advanced-search .note{
   margin-bottom: 5px;
}
.advanced-search .slider-container{
	margin-top: 40px;
}
.advanced-search .slider-container:first-child{
	display:none !important;
}
.advanced-search .slider-container .scale{
	display: none;
}
.advanced-search .theme-green .back-bar{
   height: 9px;
   background:#333333;
   border-radius: 5px;
}
.advanced-search .theme-green .back-bar .selected-bar {
	background:#333333;
}
.advanced-search .theme-green .back-bar .pointer{
	width: 17px;
	height: 17px;
	top:-4px;
	border:0;
	background:#999999;
	cursor: pointer;
}
.advanced-search .theme-green .back-bar .pointer-label{
	font-size: 13px;
	color: #000000;
	background:transparent;
	top: -25px;
}
.info-coach h3{
	font-size: 17px;
	font-weight: normal;
	color: #000000;
	position:relative;
	margin-bottom: 26px;
	margin-top:-20px;
}
.info-coach h3 a{
	color: #000000;
	text-decoration:none;
}
.info-coach h3:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.info-coach p{
	font-size: 13px;
	font-weight: normal;
	color: #000000
}
.coaches .bottom-article{
	min-height: 70px;
	padding-bottom: 0;
}
.coaches .bottom-article-search {
    min-height: 80px;
}
.coaches .bottom-article .one-info{
   width: 20%;
   float: left;
}
.coaches .bottom-article .one-info.too-info{
	width: 40%;
}
.coaches .bottom-article .one-info p{
	font-size: 14px;
}
.coaches .bottom-article .one-info.one-price{
   margin-top: -4px;
}
.coaches .bottom-article .one-info.one-certif{
   margin-top: -4px;
}
.coaches .bottom-article .one-info span.price{
	font-size: 18px;
}
.coaches .star-rating .clear-rating,
.coaches .star-rating .caption{
	display: none;
}
/*.rating-container:before,
.coaches .rating-container .rating-stars:before {
    content: attr(data-content);
    text-shadow: 0 0 2px rgb(253, 225, 109);
}*/
.rating-container{
	font-size: 12px;
	/*z-index: -9999;*/
	/*color: #FFFFFF;*/
	margin-top: 3px;
}
/* Page Blog
/* --------------------------------------------------------------------------------------------------------------- */
.newsletter{
	margin-bottom: 75px;
}
input[type="text"]:not(.form-control),
input[type="email"]:not(.form-control),
input[type="number"]:not(.form-control),
input[type="password"]:not(.form-control),
input[type="url"]:not(.form-control) {
	width: 100%;
	height: 35px;
	border:1px solid #cacaca;
	padding-left: 15px;
	margin-bottom: 15px;
}
.btn-gris{
	color: #fff;
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	border-radius: 0;
	background: #cacaca;
	-webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.btn-gris i{
	margin-right: 15px;
}
.btn-gris:hover,
.btn-gris:focus{
	color: #fff;
	background: #b9b9b9;
}
.portfolio{}
.simplefilter{
	width: 100%;
	background:#f0f0ef;
	padding-top: 10px;
	display: inline-block;
	margin-bottom: 70px;
}
.simplefilter li{
	width: 100%;
	height: 40px;
	background: #c7c7c5;
	text-align: center;
	margin-bottom: 10px;
	position: relative;
	border-radius: 0;
}
.simplefilter li.active,
.simplefilter li.active:hover,
.simplefilter li.active:focus,
.simplefilter li:hover,
.simplefilter li:focus{
	background: #333333;
	color: #fff;
}
.simplefilter li input[type="radio"]{
	position: absolute;
	opacity: 0;
}
.filtr-item{
	margin: 0 auto;
	margin-bottom: 20px;
}
.grid-sizer,
.filtr-item { 
	width: 280px; 
}
.card-container{
	margin-bottom: 20px;
}
.card-container img{
	width: 100%;
}
.disc-item{
	background: #f2f1f0;
	padding: 15px;
	display: inline-block;
	width: 100%;
}
.disc-item p.date{
   font-size: 13px;
   margin-bottom: 10px;
}
.disc-item p.date i{
	margin-right: 10px;
}
.disc-item h2{
	font-size: 18px;
	font-family: 'Raleway';
	margin-bottom: 25px;
	position: relative;
	line-height: 25px;
	font-weight: 300;
}
.disc-item h2:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.info-numbres{
	margin-top: 15px;
}
.heart{
	float: left;
	background: url('../images/heart.png') no-repeat;
	width: 24px;
	height: 21px;
	margin-right: 12px;
	text-align: center;
	line-height: 20px;
	font-size: 10px;
	color: #fff;
	font-weight: normal;
}
.descusion{
	float: left;
	background: url('../images/descution.png') no-repeat;
	width: 24px;
	height: 21px;
	text-align: center;
	line-height: 20px;
	font-size: 10px;
	color: #fff;
	font-weight: normal;
}
.suite{
	width: 105px;
	height: 30px;
	margin-top: 10px;
}
.btn-white{
	background:#fff;
	font-size: 14px;
	color: #999999;
	font-weight: 300;
	border-radius: 0;
	-webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.btn-white:hover,
.btn-white:focus{
	background: #cfcecd;
	color: #fff;
}
.filtr-container{

}

/* Page Detail séjour
/* --------------------------------------------------------------------------------------------------------------- */
.contenu-detail{
	padding-top: 25px;
    padding-bottom: 65px;
}
/*.sejour-detail{
	padding-right: 0;
}*/
.sejour-detail .col-md-6{
	padding: 0;
}

.sejour-detail h2{
	font-size: 23px;
	font-weight: normal;
	color: theme-color(secondary);
	position: relative;
	margin-bottom: 30px;
}
.sejour-detail h2:after{
	    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -7px;
    left: 0;
}

.sejour-detail .first-detail{
	text-align: justify;
}
.sejour-detail .first-detail h3{
	font-size: 18px;
	font-weight: 300;
	color: #000000;
	text-transform: uppercase;
	margin-bottom: 15px;
	margin-top: 43px;
	padding-top: 10px;
}
.sejour-detail .first-detail p{
	font-size: 16px;
	font-weight: 300;
	color: #333;
	margin-bottom:15px;
}
.sejour-detail .first-detail a{
	text-decoration: none;
	font-weight: 300;
}

.sejour-detail .one-detail{
	background: #f3f3f3;
}
.sejour-detail .one-detail.detail-map{
	background: #e5e5e5;
}
.sejour-detail .one-detail .image-sejour{
	position:relative;
}
.sejour-detail .one-detail .img-one{
	width: 100%;
}
.sejour-detail .openDiapo {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 18px;
	font-weight:100;
    cursor: pointer;
    color: white;
    z-index: 10;
    padding: 5px 12px;
    text-align: center;
}
.sejour-detail .one-detail iframe{
	margin-bottom: 30px;
	min-height: 250px;
}
.sejour-detail .one-detail .desc-one {
	padding: 20px;
	display: inline-block;
    width: 100%;
    text-align: justify;
    text-justify: inter-word;
}
.sejour-detail .one-detail.detail-map .desc-one{
	padding-bottom: 15px;
}
.sejour-detail .one-detail .desc-one p{
	font-size: 15px;
	color: #333;
	font-weight: 300;
	margin-bottom: 20px;
}
.sejour-detail .one-detail .desc-one p span{
	color: #000;
	text-transform: uppercase;
}
.sejour-detail .one-detail .panel-group .panel+.panel{
	margin-top: 12px;
}
.sejour-detail .one-detail .panel-default{
	border:0;
	border-radius: 0;
	box-shadow: none;
}
.sejour-detail .one-detail .panel-default>.panel-heading{
	border:0;
	background-color: #f7f7f6;
	position: relative;
}
.sejour-detail .one-detail .panel-default>.panel-heading h4{
	color: #000000;
	font-size: 17px;
	font-weight: 300;
}
.sejour-detail .one-detail .panel-default>.panel-heading a{
	text-decoration: none;
	float: right;
	position: absolute;
    right: 6px;
    top: 10px;
}
.sejour-detail .one-detail .panel-default>.panel-heading a .fa-minus-circle{
	display: block;
}
.sejour-detail .one-detail .panel-default>.panel-heading a .fa-plus-circle{
	display: none;
}
.sejour-detail .one-detail .panel-default>.panel-heading.collapsed a .fa-minus-circle{
	display: none;
}
.sejour-detail .one-detail .panel-default>.panel-heading.collapsed  a .fa-plus-circle{
	display: block;
}
.sejour-detail .one-detail .panel-default>.panel-heading+.panel-collapse>.panel-body{
	border-top: 0;
	border-bottom: 0;
	background-color: #e5e5e5;
}
.top-desc-golf{
	min-height: 160px;
}
.top-desc-golf .left-golf{
	min-height: 160px;
	float: left;
	width: 70px;
	margin-right: 15px;
}
.top-desc-golf .left-golf p{
	text-align: center;
	margin-bottom: 30px;
	padding-top: 10px;
	cursor: pointer;
}
.top-desc-golf .left-golf p i{
	font-size: 15px;
	margin-bottom: 5px;
}
.top-desc-golf .left-golf p span{
	text-transform: uppercase;
	font-size: 11px;
}
.top-desc-golf .right-golf p{
	color: #333;
	font-size: 15px;
	line-height: 25px;
	text-align: justify;
}
.top-desc-golf .right-golf a{
    text-decoration: none;
    color: theme-color(secondary);
    font-size: 13px;
    font-weight: 300;
}
.icones{
	width: auto;
	margin: 0 auto;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
}
.icones ul li{
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border:1px solid #333;
	margin-right: 5px;
	position: relative;
}
.icones ul li.li-icon{
	padding-top: 3px;
}
.icones ul li.li-img{
	padding-top: 13px;
}
.icones ul li.men img{
	margin-top: -5px;
}
.icones ul li.hole img{
	margin-top: -5px;
}
.icones ul li.bag img{
	margin-top: -5px;
}
.icones ul li:last-child{
	margin-right: 0;
}
.icones ul li i{
	line-height: 40px;
	font-size: 15px;
}
.icones ul li img{
	position: relative;
}
.caracteristique{
	margin-bottom: 35px;
}
.caracteristique h3{
	text-transform: uppercase;
	color: #000000;
	font-size: 18px;
	margin-bottom: 20px;
}
.info-carac{
	margin-bottom: 25px;
}
.info-carac h4{
	font-size: 16px;
	font-weight: normal;
	color: #010101;
	margin-bottom: 15px;
}
.info-carac ul{}
.info-carac ul li{
	font-weight: 300;
	font-size: 14px;
	color: #333;
	min-height: 30px;
	width: auto;
	line-height: 30px;
	margin-left: -15px;
    margin-right: -15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.info-carac ul li:nth-child(even){
	background: transparent;
}
.info-carac ul li:nth-child(odd){
	background: #eeeeee;
}
.info-carac ul li span{
	float: right;
	padding-right: 15px;
	text-transform: uppercase;
}
.acceder{}
.one-acceder{
	margin-bottom: 17px;
	min-height: 40px;
}
.one-acceder .img-acceder{
	width: 40px;
	float: left;
	margin-right: 10px;
}
.one-acceder .img-acceder span{
	width: 40px;
	height: 40px;
	border-radius: 50%;
    border: 1px solid #333;
    display: block;
    text-align: center;
}
.one-acceder .img-acceder span i{
	line-height: 40px;
    font-size: 15px;
}
.one-acceder .desc-acceder{}
.one-acceder .desc-acceder h5{
	font-weight: 300;
	font-size: 14px;
	color: #000;
}	
.one-acceder .desc-acceder p{
	font-weight: 300;
	font-size: 14px;
	color: #333;
}
.sejour-detail .detail-hebergement{
	background:#f7f7f6;
}
.sejour-detail .detail-hebergement .top-desc-golf .left-golf p{
	color: #333333;
	margin-bottom: 13px;
	cursor:pointer;
}
.sejour-detail .detail-hebergement .top-desc-golf .left-golf p a{
	text-decoration:none;
	color: #333333;
}
.sejour-detail .detail-hebergement .panel-default>.panel-heading{
	background:#eeeeee;
	position: relative;
	cursor:pointer;
}
.sejour-detail .detail-hebergement .panel-default>.panel-heading+.panel-collapse>.panel-body{
	background:#f7f7f6;
}
.sejour-detail .detail-hebergement .panel-group{
	margin-bottom: 0;
	padding-bottom: 30px;
}
.carousel{
	width: 100% !important;
}
#slides {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
}

#slides ul {
  list-style: none;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
}

#slides li {
  width: 100%;
  height: auto;
  float: left;
  position: relative;
}
.btn-bar {
  width: 100%;
  display: inline-block;
  position: relative;
}
.btn-bar h3{
	color: #000000;
    font-weight: 300;
    font-size: 16px;
    height: 35px;
    background-color: #eceeeb;
    padding-left: 10px;
    line-height: 35px;
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 200;
    text-align: left !important;
}
.buttons {
  padding: 0 0 5px 0;
  float: right;
  position: relative;
  right: 5px;
  top: -25px;
}
.buttons a {
  width: 15px;
  height: 15px;
  float: left;
  font-size: 17px;
  outline: 0;
  text-decoration: none;
  display: block;
  margin-left: 10px;
  border-radius: 50%;
  background:url('../images/row-slider.png') no-repeat;
}
.buttons a#prev {
	background-position: 0% 0%;
}
.buttons a#next {
	background-position: 100% 0%;
}
.quote-phrase{
	padding-left: 10px;
	border-left:3px solid #fbfbfb;
}
.sejour-detail .one-detail.detail-avis {
    background: #f8f8f7;
}
.sejour-detail h5{
	color: #030303;
	font-size: 17px;
	margin-bottom: 3px;
}
.sejour-detail ul{
	margin-bottom: 20px;
}
.sejour-detail .puce li{
	margin-right: 5px;
	list-style-type: none;
    background: url(../../IMAGES/icon-ok-compte.png) no-repeat;
    padding-left: 25px;
    margin-bottom: 15px;
}
.statistiques{
	display: inline-block;
	width: 100%;
	padding-top: 25px;
	padding-bottom: 25px;
}
.statistiques .col-md-5{
	padding-left: 0;
}
.pie-title-center {
  display: inline-block;
  position: relative;
  text-align: center;
}

.pie-value {
  display: block;
  position: absolute;
  font-size: 13px;
  color: #999999;
  height: 40px;
  top: 45%;
  left: 0;
  right: 0;
  margin-top: -20px;
}
.value-pie{
	font-size: 18px;
	color: #000000;
	font-weight: normal;
}
.one-progress{}
.one-progress h5{
	color: #acacac;
	font-size: 13px;
	margin-bottom: 5px;
}
.one-progress h5 span{
	color: #000;
}
.one-progress .progress{
	height: 12px;
	background-color: #e6e6e6;
	border:0;
	border-radius: 0;
}
.one-progress .progress-bar{
	background-color: #c6c6c5;
}
.last-avis{
	background-color: #f3f3f3;
	position: relative;
	padding: 15px;
	padding-left: 35px;
}
.last-avis h5{
	text-transform: uppercase;
	font-size: 18px;
	color: #010101;
}
.control-last{
	width: 30px;
	height: 62px;
	position: absolute;
	right: 0;
	top: 0;
}
.control-last a{
	text-align: center;
	width: 100%;
	height: 30px;
	background-color: #797979;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	line-height: 30px;
	font-size: 20px
}
.control-last a:first-child{
	margin-bottom: 2px;
}
.control-last a i{
	line-height: 30px;
}
#last-avis{
	/*height: 650px !important;*/

}
#last-avis li{
	padding-top: 25px;
	padding-bottom: 25px;
	border-bottom: 1px solid #e6e6e6;
	font-size: 14px;
	color: #000;
}
#last-avis li .gris-last{
	color: #999999;
	border-left: 3px solid #acacac;
	padding-left: 15px;
	margin-bottom: 15px;
}
#last-avis li p{
	padding-left: 17px;
}
#last-avis li .gris-last p{
	padding-left: 0;
}

.sidebar{
	padding-left: 0;
}
.reservation{
	background-color: #f7f7f6;
	margin-bottom: 30px;
}
.reservation .top-reservation{
	border-bottom:1px solid #000;
	padding: 15px;
	position: relative;
}
.reservation .top-reservation h5{ 
	text-transform: uppercase;
	font-size: 18px;
	font-weight: normal;
	color: #000;
}
.reservation .top-reservation h5:after{
	content: ' ';
    width: 50px;
    height: 3px;
    background: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.reservation .top-reservation span{
	font-size: 11px;
	font-weight: 300;
	color: #333;
	padding-top: 3px;
	font-style: italic;
}
.reservation .top-reservation .tel{
	font-size: 14px;
    margin-top: 10px;
    display: inline-table;
    /* margin: 0 auto; */
    font-weight: bold;
}
.reservation .content-reservation{
	min-height: 150px;
	padding: 20px 25px;
}
.search input[type='text'],
.search input[type='email'],
.search input[type='date']{
	border:1px solid #9b9b9a;
	background:#f7f7f6;
	border-radius: 0;
	 -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    width: 100%;
    height: 35px;
    text-overflow: '';
    padding-left: 15px;
    margin-bottom: 15px;
}
.search select:focus{
	box-shadow: none;
	border:1px solid #9b9b9a;
}
.search textarea{
	margin-top: 15px;
	border:1px solid #9b9b9a;
	background:#f7f7f6;
	border-radius: 0;
	width: 100%;
    height: 115px;
	padding-left: 15px;
    margin-bottom: 15px;
}
.reservation .content-reservation h5{
	font-size: 14px;
	font-weight: 300;
	color: #000;
	margin-bottom: 30px;
	text-transform: uppercase;
	margin-top: 10px;
}
.reservation .content-reservation h5 i{
	float: right;
}
.reservation .content-reservation h5 .fa-pencil-square-o{
	display:block !important;
	color:#000 !important;
}
.reservation .content-reservation .cadeau{
	background: theme-color(secondary);
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 25px 15px;
	color: theme-color(white);
}
.reservation .content-reservation .cadeau i{
	color: theme-color(white);
    font-size: 20px;
}
.reservation .content-reservation .cadeau p{
	font-size: 14px;
	color: theme-color(white);
	font-weight: 300;
}
.reservation .content-reservation .cadeau p.white{
	color: #fff;
	font-size: 13px;
}
.reservation .content-reservation .info-stage{
	color: #999999;
	font-size: 12px;
	font-weight: 300;
}
.reservation .result-stage{
	background: #e5e5e5;
	padding: 25px 15px;
}
.reservation .result-stage p{
	text-align: left;
    font-size: 20px;
    color: #000000;
    font-weight: normal;
    /*padding-left: 5px;*/
    position: relative;
    width: 160px;
}
.reservation .result-stage p .resultat{
	float:right;
	padding-right:13px;
	text-align:left;
	position: absolute;
    right: -95px;
}
.reservation .result-stage button{
	margin-top: 30px;
}
.datepicker-inline{
	margin-bottom: 20px;
}
.datepicker-inline .datepicker{
	width: 100%;
	border:0;
	border-radius: 0;
	background: transparent;
}
.datepicker--content {
    padding: 0;
}
.datepicker--nav{
	border-bottom: 0;
}
.datepicker--nav-action{
	width: 16px;
	height: 16px;
	margin-top: 4px;
}
.datepicker--day-name{
	color: #000;
	text-align: left !important;
    display: inline-block;
}
.datepicker--cell-day{
	border: 1px solid #000;
    border-radius: 0;
    margin-right: -1px;
    margin-top: -1px;
    font-size: 12px;
}
.datepicker--cell.-selected-, 
.datepicker--cell.-selected-.-current-,
.datepicker--cell.-selected-:hover, 
.datepicker--cell.-selected-.-current-:hover{
	background: #333;
	color: #fff;
}

.box_credit{
    width: 100%;
    background-color: #F7F7F7;
    display: inline-table;
    padding: 10px;
    text-align: center;
}
.box_credit .checkbox{
	margin-left: 13px;
}
.datepicker--cell.-current-{
	color: #fff;
	background-color:#F35D20;
}
.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade-{
	color: #000;
}
.dp-note{
	position: absolute;
    bottom: 1px;
    left: 10%;
    font-size: 10px;
    color: theme-color(secondary);
}
.dp-note.promo{
	color: #ccac59;
}
.datepicker--cell-day.active{
	background: #eeeeee;
	padding-bottom: 10px;
}
.dp-note{
	font-weight: bold;
}
.info-calendar{
	margin-bottom: 30px;
}
.info-calendar ul >li {
	color: #000000;
	font-size: 12px;
	font-weight: 300;
	margin-right: 25px;
}
.info-calendar ul >li span {
	width: 8px;
	height: 8px;
	display: inline-block;
}
.info-calendar ul >li.dispo span {
	background: theme-color(secondary);
}
.info-calendar ul >li.promo span {
	background: #ccac59;
}

.promotion{
	margin-top: 0px;
    width: auto;
    background-color: #f7f7f7;
    text-align: right;
    font-weight: bold;
    float: none;
    height: auto;
    margin-right: 0;
    width: 100%;
    padding-right: 15px;
}
.complet{
	margin-top:10px;width:70px;background-color:#f7f7f7;text-align:center;font-weight: bold;float:left;height:20px;margin-right:5px;
}
.coeur{
	margin-top: 2px;width: 18px;color:#f7f7f7;text-align:center;font-weight: bold;float:left;height:20px;margin-right:5px;
}
.niveau{
	float:left;margin-right:5px;
}
.infos_stage{
	position:absolute;right: 10px;bottom: 18px;
}

/* Page Coach
/* --------------------------------------------------------------------------------------------------------------- */
.info-coach{
	margin-top: 20px;
}
.info-coach .top-coach{
	display: inline-block;
    width: 100%;
    margin-bottom: 35px;
	background:#f7f7f7;
}
.info-coach .photo{
	padding-left:0;    
	width: 48%;
    float: left;
	position: relative;
	margin-right: 15px;}
.info-coach .img-coach{
	
}
.info-coach .informations{
	padding-left:15px;
	padding-top:24px;
	/*padding-right: 50px;*/
	padding:0;
}
.info-coach .description-coatch{
	padding-right: 15px;
    padding-top: 4px;
	padding-left: 15px;
}
.info-coach .informations h1{
	font-size: 24px;
    font-weight: normal;
    color: #f35d20;
    position: relative;
    margin-bottom: 26px;
	position:relative;
	float: left;
    width: 49%;
}
.info-coach .informations h1 span{
	/*width:345px;*/
	max-width: 368px;
	display: inline-block;
}
.info-coach .informations h1:after {
    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.info-coach .informations p{
	font-size: 14px;
    font-weight: 300;
    color: rgb(102, 102, 102);
}
.info-coach .informations a{
	text-decoration: none;
    color: rgb(242, 93, 32);
    font-size: 13px;
    font-weight: 300;
	/*float: left;*/
	margin-bottom:0px;
}
	
.icon-infos{
	margin-top: 0px;
	width: 48%;
    float: left;
}
.one-ico{margin-bottom: 2px;}
.one-ico .left-ico{
	width:20px;
	float:left;
	min-height: 48px;
}
.one-ico .right-ico{
	
}
.one-ico .right-ico h6{
	text-transform:uppercase;
	    font-size: 14px;
	    display: inline-table;
}
.one-ico .right-ico p{margin-top:0;color:#747474;font-size: 15px;}
.certif-right{
	position: absolute;
    right: 5px;
    top: 5px;
}
.info-coach .star-rating .clear-rating,
.info-coach .star-rating .caption{
	display: none;
}
.info-coach .rating-container{
	font-size: 15px;
	
	margin-top: 0px;
	float: left;
}
.info-coach .avis{
	margin-left: 15px;
}
.info-coach p{
	margin-top: 20px;
	font-size: 14px;
}
.info-coach p .certificate{
	float: left;
	margin-right: 15px;
}
.desc-coach .tab-pane{
	height: auto;
	padding: 25px;
	min-height: 456px;
}
.one-stage{
	-webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    margin-bottom: 30px;
}
.one-stage .img-stage{
	width: 100%;
	height: auto;
	position: relative;
}
.one-stage .img-stage img{
	width: 100%;
}
.one-stage .img-stage .bottom-img{
	width: 100%;
    height: 110px;
    background: rgba(255,255,255,0.8);
    position: absolute;
    bottom: -1px;
    left: 0;
    padding: 25px;
}
.one-stage .img-stage .bottom-img h2{
	font-size: 18px;
    font-weight: normal;
    color: #000000;
    position: relative;
    margin-bottom: 26px;
}
.one-stage .img-stage .bottom-img h2:after {
    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.one-stage .bottom-stage{
	padding: 25px;
    padding-bottom: 20px;
    display: inline-block;
    width: 100%;
    min-height: 90px;
    background: #fff;
}
.one-stage .bottom-stage .info-bottom{
	display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}
.one-stage .bottom-stage .info-bottom .one-info {
    width: 33%;
    float: left;
}
.one-stage .bottom-stage .info-bottom .one-info.prix {
    width: 100%;
    float: left;
}
.one-stage .bottom-stage .info-bottom .one-info.prix p{
	font-size: 16px;
	color: theme-color(secondary);
}
.one-stage .bottom-stage .info-bottom .one-info p{
	font-size: 14px;
}
.one-stage .bottom-stage .desc-bottom{
	position: relative;
	padding-bottom: 40px;
}
.one-stage .bottom-stage .desc-bottom p{
	font-size: 14px;
    margin-top: 0;
}
.one-stage .bottom-stage .desc-bottom .btn-savoir{
	border:0;
	border-radius: 0;
	background: #f3f3f3;
	text-decoration: none;
	color: #333333;
	position: absolute;
	bottom: 0;
	right: 0;
	height: 32px;
	-webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.one-stage .bottom-stage .desc-bottom .btn-savoir:hover{
	background: #999;
	color: #fff;
}
#one-stage-avis{
	margin: 0 auto;
    display: block;
    margin-top: 25px;
}
.stati-stage .pie-value{
	margin-top: -20px;
}

/* Page MRP
/* --------------------------------------------------------------------------------------------------------------- */
.latest-videos{
	margin-bottom: 60px;
}
.latest-videos h3{
	text-align: left;
	font-weight: normal;
	font-family: 'Raleway';
	font-size: 19px;
	color: #000000;
	text-transform: uppercase;
	position: relative;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 12px;
}
.latest-videos h3:after {
    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -1px;
    left: 0;
}
.bx-wrapper .bx-viewport{
	box-shadow: none;
	border:0;
	margin-top: 35px;
}
.bx-controls-direction{
	padding: 0 0 5px 0;
    float: right;
    position: absolute;
    right: 15px;
    width: 25px;
    top: -45px;
}
.bx-wrapper .bx-controls-direction a{
	width: 15px !important;
    height: 15px !important;
    float: left;
    font-size: 17px;
    outline: 0;
    text-decoration: none;
    display: block;
    margin-left: 10px;
    border-radius: 50%;
    background: url('../images/row-slider.png') no-repeat;
}
.bx-wrapper .bx-controls-direction a.bx-prev{
	background-position: 100% 0%;
}
.bx-wrapper .bx-controls-direction a.bx-next{
	background-position: 0% 0%;
}
.mrp-steps{
	border:1px solid #cfcfcf;
	display:inline-block;
	width: 100%;
}
.mrp-steps .one-step{
	border-bottom: 1px solid #cfcfcf;
	display: inline-block;
}
.mrp-steps .one-step:last-child{
	border-bottom: 0;
}
.mrp-steps .one-step .desc-mrp{
	padding-top: 25px;
}
.mrp-steps .one-step .desc-mrp h3{
	color: theme-color(secondary);
	font-size: 22px;
	font-weight: normal;
	font-family: 'Raleway';
	margin-bottom: 50px;
	position: relative;
}
.mrp-steps .one-step .desc-mrp h3:after {
    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -7px;
    left: 0;
}
.mrp-steps .one-step .desc-mrp p{
	color: #333;
    font-size: 15px;
    margin-bottom: 18px;
}
.mrp-steps .one-step .desc-mrp p a{
	text-decoration: none;
	color: theme-color(secondary);
}
.mrp-steps .one-step.right .img-mrp{
	padding-right: 0;
}
.mrp-steps .one-step.left .img-mrp{
	padding-left: 0;
}
.mrp-steps .one-step .img-mrp img{
	width: 100%;
}
.niveau-mrp{}
.niveau-mrp .description p{
	margin-bottom: 0;
}
.niveau-mrp .description a{
	text-decoration: none;
	color: theme-color(secondary);
	font-size: 13px;
}
.level{
	margin-top: 65px;
}
.level .one-level{
	background: #f3f3f3;
	margin-bottom: 20px;
}
.level .one-level .img-level{}
.level .one-level .img-level img{
	width: 100%;
}
.level .one-level .desc-level{
	padding: 25px;
}
.level .one-level .desc-level h4{
	font-size: 18px;
	color: #010101;
	text-transform: uppercase;
	margin-bottom: 10px;
	position: relative;
}
.level .one-level .desc-level .title-suparator{
	width: 50px;
    height: 1px;
    background: #333333;
    margin: 0 auto;
    margin-bottom: 35px;
    margin-top: 10px;
}
.level .one-level .desc-level p{
	margin-bottom: 25px;
}
.level .one-level .desc-level .btn-level{
	border:0;
	border-radius: 0;
	background: #ffffff;
	text-decoration: none;
	color: #333333 !important;
	-webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out; 
}
.level .one-level .desc-level .btn-level:hover{
	background: #999;
	color: #fff !important;
}

/********/
.login-membre{
	width: 160px;
	height: 200px;
    border-radius: 3px;
    margin-top: -7px;
}
.login-membre input[type="text"], 
.login-membre input[type="password"]{
	width: 100%;
	margin-right: 0;
}
.login-membre .btn-submit{
	width: 100%;
}
.login-membre p {
    width: 100%;
    float: none;
    text-align: center;
    margin-top: 10px;
    position: relative;
    display: inline-block;
}
.login-membre p a{
	width: 100%;
	margin-bottom: 10px;
	font-size: 13px !important;
}
.login-membre p .forget {
    margin-left: 0;
}
/*Modal insceiption*/
.infos-modals{
	padding-left: 0;
	background: rgba(240, 240, 240, 1);
	display: inline-block;
    width: 100%;
}
.infos-modals .form-control {
    background: rgba(240, 240, 240, 1);
}
#signin .modal-header,
#forgetPass .modal-header{
	border-bottom: 0;
	position: absolute;
	width: 100%;
	z-index: 9;
}
.contenu_user_pop{
	padding-top: 20px;
    padding-bottom: 10px;
    display: inline-block;
    width: 100%;
}
.informations-pop{
	padding-left: 0;
}
.informations-pop p.form-info {
    padding-top: 0;
}
.output{
    display: inline-block;
    min-height: 250px;
	width: 100% !important;
	margin-bottom: 22px;
	margin-left: 0px;
}
.gldp-flatwhite{
	position:relative;
	font-family: 'Proxima Nova';
	top:0 !important;
	left:0 !important;
	width:225px !important;
}
.gldp-flatwhite .monyear{
	width:32px !important;
	height:32px !important;
	background:#f7f7f6;
}
.gldp-flatwhite .monyear.title{
	width:163px !important;
}
.gldp-flatwhite .monyear.title span{
	font-size:13px;
	font-weight:300;
}
.gldp-flatwhite .noday{
	width:32px !important;
	height:32px !important;
	background:#f7f7f6;
	font-size: 12px;
	line-height: 22px !important;
}
.gldp-flatwhite .dow{
	width:32px !important;
	height:32px !important;
	background:#f7f7f6;
}
.gldp-flatwhite .fri, 
.gldp-flatwhite .mon, 
.gldp-flatwhite .sat, 
.gldp-flatwhite .sun, 
.gldp-flatwhite .thu, 
.gldp-flatwhite .tue,
.gldp-flatwhite .wed{
	width:32px !important;
	height:32px !important;
	background: #eeeeee;
    padding-bottom: 10px;
	font-size:12px !important;
	color:#000 !important;
	font-weight:300 !important;
}
.gldp-flatwhite .selected, 
.gldp-flatwhite .today,
.gldp-flatwhite .core{
	width:32px !important;
	height:32px !important;
	/*background: #eeeeee;*/
    padding-bottom: 10px;
	font-size:12px !important;
	color:#000 !important;
	font-weight:300 !important;
}
.gldp-flatwhite .border{
	border-color:#000;
}
.prix_calendar{
	font-size: 10px !important;
    color: #fff !important;
    font-weight: bold !important;
    margin-top: -5px;
}
.gldp-flatwhite .selected, .gldp-flatwhite .today{
	background: #A2A2A2;
	color:#333 !important;
}
.gldp-flatwhite .fri:hover, .gldp-flatwhite .mon:hover, .gldp-flatwhite .sat:hover, .gldp-flatwhite .sun:hover, .gldp-flatwhite .thu:hover, .gldp-flatwhite .tue:hover, .gldp-flatwhite .wed:hover{
	background: theme-color(secondary) !important;
}
.gldp-flatwhite .fri, .gldp-flatwhite .mon, .gldp-flatwhite .sat, .gldp-flatwhite .sun, .gldp-flatwhite .thu, .gldp-flatwhite .tue, .gldp-flatwhite .wed{
	background: theme-color(secondary) !important;
	color:#fff !important;
}
#bloc_hotel_debut_choix_ok,
#bloc_hotel_fin_choix_ok,
#bloc_nbr_stage_ok,
#bloc_stage_debut_ok,
#choix-hotel-stage-ok {
    margin-top: 25px !important;
	border-bottom: #ddd solid 1px;

	.numResa {
		cursor: pointer;
		display: flex;
		flex-wrap: wrap;
		
		&, & * {
			float: none !important;
		}

		* {
			flex: auto;
		}

		span {
			max-width: 50%;
			font-weight: 600;
		}

		i.fa-pencil-square-o {
			text-align: right;
			justify-self: end;

			&:after {
				content: "Modifier";
				font-size: 8px;
				font-family: Arial, Helvetica, sans-serif;
				display: block;
				margin-top: 3px;
			}
		}
	}
}
.select-target.select-theme-default{
	background:transparent;
}
.select.select-theme-default .select-content{
	width:100%;
	max-width:100%;
}
#details-right fieldset .btn_panier_inactif{
	    background: #333333;
    border-radius: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    font-family: 'Raleway';
}
#details-right fieldset .btn_panier_actif, #details-right fieldset .btn_panier_inactif{
	    background: #333333;
    border-radius: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    font-family: 'Raleway';
	width:100%;
}
/* Page Process commande
/* --------------------------------------------------------------------------------------------------------------- */
.etat-steps{
	margin-top: 180px;
    margin-bottom: 80px;
    display: inline-block;
    width: 100%;
}
.etat-steps .col-sm-4{
	padding-right: 0;
}
.one-etat{
	background: #bfbfbf;
	height: 50px;
	text-align: center;
}
.one-etat:hover,
.one-etat.active{
	background: #787878;
}
.one-etat a{
	line-height: 50px;
	text-decoration: none;
	font-size: 18px;
	color: #ffffff;
	position: relative;
	width: 100%;
	display: block;
}
.one-etat a span{
	position: absolute;
    left: 15px;
    font-size: 26px;
    font-weight: bold;
    background: url('../images/shape.png') no-repeat 100%;
    width: 37px;
    text-align: left;
}
.selection{
	margin-bottom: 60px;
}
.selection h3{
	font-size: 19px;
	color: theme-color(secondary);
	text-transform: uppercase;
	margin-bottom: 30px;
}
.selection h3 .orange{
	font-weight: bold;
    font-size: 20px;
    color: #fff;
    background: theme-color(secondary);
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: block;
    line-height: 42px;
    text-align: center;
    float: left;
    margin-top: -13px;
    margin-right: 10px;
}
.one-selection{
	padding-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #f6f6f6;
}
.one-selection .rappel{
	color: theme-color(secondary);
	font-size: 19px;
	margin-bottom: 55px;
	text-transform: uppercase;
	margin-left: 45px;
	position: relative;
}
.one-selection .rappel:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: theme-color(secondary);
    position: absolute;
    bottom: -15px;
    left: 0;
}
.info-selection{
	padding-top: 30px;
	padding-left: 40px;
	padding-bottom: 25px;
	padding-right: 20px;
	background:  #f6f6f6;
}
.info-selection h2{
	font-size: 21px;
	color: #000000;
	position: relative;
	margin-bottom: 40px;
}
.info-selection h2:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.info-selection p{
	font-size: 14px;
	color: #000000;
	margin-bottom: 25px;
}
.info-selection ul{}
.info-selection ul li{
	color: #999999;
	font-size: 14px;
}
.info-selection ul li span{
	float: right;
	text-align: right;
	color: #000;
}
.select-infos{
	
}
.left-golf-img{
	float: left;
	margin-right: 15px;
	width: 150px;
	min-height: 120px;
}
.left-golf-img img{
	width: 100%;
}
.right-golf-img p{
	color: #000000;
	font-size: 13px;
	margin-bottom: 12px;
}
.actions-selection{}
.actions-selection .delete{
	padding-right: 0
}
.actions-selection .edit{
	padding-right: 0;
}
.actions-selection .stage-selection{
	margin-left: 15px;
	background: #f6f6f6;
	margin-top: 20px;
}
.actions-selection .stage-selection h5{
	text-align: center;
	padding-top: 30px;
	padding-bottom: 30px;
	color: #000000;
	font-size: 21px;
	font-weight: normal;
}
.comprend{
	margin-top: 40px;
}
.comprend h4{
	font-weight: bold;
	margin-bottom: 10px;
	text-transform: uppercase;
}
.comprend p{
	margin-bottom: 10px;
}
.block-gris{
	padding: 25px 30px;
	background: #f6f6f6;
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 15px;
}
.assurance{

}
.assurance h4{
	font-size: 21px;
	color: #000000;
	position: relative;
	margin-bottom: 50px;
}
.left-assurence{
	padding-right: 0;
}
.right-assurence{
	padding-left: 0;
}
.assurance h4:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -15px;
    left: 0;
}
.right-assurence p{
	font-size: 14px;
	color: #333;
	margin-top: 15px;
}
.right-assurence ul>li{
	margin-bottom: 0px;
    position: relative;
    display: inline-block;
    width: 100%;
    line-height: 40px;
    font-size: 14px;
	color: #333;
}

.right-assurence h5{
	font-weight: normal;
	font-size: 21px;
	color: #000000;
	text-align: right;
	margin-top: 30px;
}

.radio, .checkbox {
	margin: 0;
	display: inline-block;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
	position: static !important;
	margin-left: 0 !important;
	margin: 0 !important;
	float: none !important;
}
.cadeau {
	padding: 30px;
	margin-bottom: 20px;
}
.code-promo label {
	margin-bottom: 15px;
}
.code-promo .code{
	width: 100%;
	border-radius: 0;
    border: 1px solid #929292;
    font-size: 14px;
    color: #000000;
    background:transparent;
}
.code-promo .cadeau h4{
	width: 122px;
	float: left;
	color: #000000;
	font-size: 18px;
	margin-right: 28px;
}
.code-promo .cadeau button{
	font-size: 14px;
	font-weight: normal;
	width: 245px;
}
.code-promo .cadeau button i{
	margin-right: 10px;
}
.total-frai{
	margin-bottom: 50px;
}
.total-frai h4{
	font-size: 19px;
    color: #000000;
    position: relative;
}
.total-frai h4:after{
	content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -7px;
    left: 0;
}
.total-frai h4 span{
	font-size: 21px;
	font-weight: normal;
	margin-left: 25px;
}
.total-frai h5{
	font-size: 21px;
    color: #000000;
    font-weight: normal;
    text-align: right;
}
.total-frai .right-h4 span{
	margin-left: 58px;
}
.selection-seconde{
	margin-bottom: 0;
}
.have-compte{
	display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}
.have-radio{
	 margin-top: 5px;
}
.have-compte .radio{
	width: 19px;
	height: 19px;
	background: url('../images/radio.png');
	background-position: 0% 0%;
	float: left;
	margin-right: 15px;
	margin-top: 5px;
}
.have-compte .radio.active{
	background-position: 0% 100%;
}
.have-compte input[type='radio']{
	opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
}
.have-compte .span-compte{
	font-size: 19px;
    color: #000000;
    display: block;
    max-width: 100%;
    margin-bottom: 0;
    font-weight: 300;
}
.have-compte .one-input{
	padding-right: 0;
}
.have-radio-register{
	margin-bottom: 75px;
}
.informations-compte{}
.informations-compte select{
	background:url('../images/arrow-select.png') no-repeat 95% #fff;
}
.informations-compte input::-webkit-input-placeholder {
   color: #000;
}

.informations-compte input:-moz-placeholder { /* Firefox 18- */
   color: #000; 
}

.informations-compte input::-moz-placeholder {  /* Firefox 19+ */
   color: #000;  
}

.informations-compte input:-ms-input-placeholder {  
   color: #000;  
}
.informations-compte .infos-form{
	font-size: 12px;
	color: #999999;
	margin-top: -15px;
}
.check-form{
	margin-top: 40px;
}
.check-form li{
	margin-bottom: 15px;
}
.total-submit{
	margin-top: 40px;
}
.total-submit h4{
	font-size: 19px;
    color: #000000;
    position: relative;
    margin-top: 7px;
}
.total-submit h4 span{
	    font-size: 21px;
    font-weight: normal;
    margin-left: 25px;
}
.parent-check {
	display: inline-block;
	margin-right: 8px;
}
.payement .actions-selection .stage-selection {
    margin-top: 0;
}
.moyen-payement{
	padding-top: 30px;
    padding-bottom: 30px;
	border-bottom: 1px solid #f6f6f6;
	display: inline-block;
	width: 100%;
}
.one-pay{
	width: 200px;
	height: 100px;
	border-radius: 5px;
	background: #f2f1f1;
	margin-left: 45px;
	float: left;
	position: relative;
	cursor: pointer;
}
.one-pay .img-pay{
	margin: 0 auto;
	margin-top: 25px;
	margin-bottom: 10px;
}
.one-pay .blue-carte{
	margin-top: 25px;
	margin-bottom: 10px;
	width: 57px;
	height: 38px;
	background: url('../images/carte-blue.png') no-repeat;
	background-position: 0% 0%;

}
.one-pay.active .blue-carte{
	background-position: 0% 100%;

}
.one-pay .virment{
	padding-top: 22px;
	margin-bottom: 5px;
	width: 51px;
	height: 46px;
	background: url('../images/virement.png') no-repeat;
	background-position: 0% 0%;

}
.one-pay.active .virment{
	background-position: 0% 100%;
}
.one-pay .check-vir{
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 9px;
	width: 64px;
	height: 43px;
	background: url('../images/pay-check.png') no-repeat;
	background-position: 0% 0%;
}
.one-pay.active .check-vir{
	background-position: 0% 100%;
}
.one-pay .vacanc{
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 5px;
	width: 67px;
	height: 48px;
	background: url('../images/vacances.png') no-repeat;
	background-position: 0% 0%;
}
.one-pay.active .vacanc{
	background-position: 0% 100%;
}
.one-pay.active{
	background: #787878;
}
.one-pay p{
	color: #000000;
	font-size: 14px;
}
.one-pay.active p{
	color: #fff;
}
.one-pay .checked-pay{
	width: 36px;
	height: 36px;
	background:url('../images/checked-pay.png') no-repeat;
	position: absolute;
	top: -20px;
    left: 80px;
    display: none;
}
.one-pay.active .checked-pay{
    display: block;
}
.one-pay .pay-input{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    opacity: 0;
    cursor: pointer;
}
.payement-info{
	font-size: 15px;
	color: #333;
	margin-left: 45px;
	margin-top: 35px;
}
.payement-info a{
	color: #333;
	text-decoration:underline;
}
.select-go{
	padding-top: 30px;
    padding-bottom: 30px;
	border-bottom: 1px solid #f6f6f6;
	display: inline-block;
	width: 100%;
}
.select-go h5{
	color: #000000;
	font-weight: normal;
	font-size: 21px;
	padding-top: 5px;
}

/*********Carte cadeau****/
.bloc-avantages-carte-icone {
    background: #e5e5e5;
    padding: 23px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.one-advant{
	margin-bottom:15px;
}
.icon-advant{
	width:60px;
	height:60px;
	border-radius:50%;
	border:1px solid #333;
	margin:0 auto;
	margin-bottom:20px;
}
.icon-advant i{
	line-height:60px;
	font-size:30px;
}
.bloc-avantages-carte {
    width: 100%;
    float: none;
    margin-bottom: 30px;
	display:inline-block;
}
.one-input-carte{
	position:relative;
}
span.euro-carte{
	width: 35px;
    height: 35px;
    position: absolute;
    top: 5px;
    right: 0px;
    font-size: 22px;
}
#content-slider-visuel{
	padding-left:0;
}
#slider-visuel-carte{
	width:100% !important;
}
#carte-cadeau fieldset {
    border: 0;
    background: #f6f6f6;
    padding: 23px;
    clear: both;
    margin-bottom: 20px;
}
#nextBtnCarte a{
	background: url('../images/right.png') no-repeat;
    width: 52px;
    height: 52px;
    right: 5px;
    top: 30px;
}
#prevBtnCarte a{
	background: url('../images/left.png') no-repeat;
    width: 52px;
    height: 52px;
    left: 10px;
    top: 30px;
}
#personnalisation-carte{
	display: inline-block;
    width: 100%;
    margin-bottom: 45px;
}
.one-input-textarea{
	margin-bottom:20px;
}
.personnalisation-col{
	margin-bottom:15px;
}
.informations .table-historique .detail table{
	width:100% !important;
}
.titre-presse{
	margin-bottom: 35px !important;
}
.titre-presse a{
	    font-size: 21px;
    color: #000000;
    position: relative;
    margin-bottom: 35px !important;
}
.titre-presse a:after{
	    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -15px;
    left: 0;
}

.different-chambre{
	margin-left: 0px;
    margin-top: 22px;
}
#formNewsletter.informations,
.informations-contact{
	padding-left:0 !important;
	margin-top: 20px;
}
#formNewsletter.informations .button-submit {
    padding-left: 15px;;
}
#contact-form-right .block-titre{
	padding-top:20px;
	padding-bottom:20px;
	font-size:24px;
}
#contact-form-right  .article-newsletter{
	padding-bottom:50px;
}
.ideal-form{
	margin-top:30px;
}
.mentions-form{
	margin-bottom:20px;
}
.msg{
	text-align:center;
	font-size:19px;
}
.bulleCalendar{
	
}
.titrePromoBulle{
	width:100%;
	height:50px;
}
.infosStageBulle{
	font-size:13px;
}
.prix_promotion2 {
    background: transparent;
    color: #FFF;
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 32px;
    text-align: center;
    border: 0;
}
#flypage .html5gallery {
    margin: 0 auto;
}
/*.html5gallery-elem-0 div {
    display: none !important;
}*/
.html5gallery-title-0 {
	padding: 0 !important;
	width: 100% !important;
}
/*****Fix menu**/
#fixed-menu {
    display: none;
}
.menu-fix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999999;
    background: #fff;
}
.menu-fix .navbar-brand{
	padding: 0;
    padding-right: 15px;
}
.menu-fix .navbar-golf .navbar-nav>li {
    padding-left: 0px;
    padding-right: 40px;
}
.menu-fix .navbar {
   	min-height: 75px;
    padding-top: 4px;
}
.menu-fix .panier-fix>a{
	padding-top: 13px;
}
.menu-fix .compte-fix>a,
.menu-fix .panier-fix>a {
   font-size: 10px !important;
}
.bienvenu-user{
	font-size:20px;
}
.bienvenu-user strong{
	text-transform:uppercase;
}

#bloc-resultat-carte-content{
	position:relative;
	width: 100%;
	height: auto !important;
}
#item-resultat-carte-content{
	/*overflow: visible;*/
    background: #fff;
	height: auto !important;
    margin-bottom: 80px;
}
#tri-resultat-carte fieldset {
    border: 0;
    border-bottom: 0;
    padding: 14px;
    padding-right: 0;
}
.item-resultat-carte .certification-prod li {
    margin-bottom: 0;
    float: left;
    margin-right: 5px;
}
#mapContainer{
	height: 65vh !important;
}
.iframe-blog{
	border:0;
	margin-bottom:50px;
}
#footer-partenaires {
    background-color: transparent;
}
#footer-partenaires div {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.select-theme-default, .select-theme-default * {
    width: 220px !important;
}

/*.reservation.reservationAbsolute11 {
    position: fixed;
    bottom: 15px;

    background: #F7F7F6;
    width: 270px;
    z-index: 10;
}*/

.readmore,.readmoreprogramme,.readmore1,.readmoresejour{
	text-align: right;
}

.readmore:hover,
.readmore:focus{
    color: theme-color(secondary);
}
.modal-header {
    border-bottom: 0;
}
/*.html5gallery{
	height: 480px !important;
}
.html5gallery.single_image{
	height: 346px !important;
}*/

[class^="html5gallery-container-"] {
	background: theme-color(white);

	[class^="html5gallery-title-"] {
		width: calc(100% - 10px);
	}
	[class^="html5gallery-elem-image-"] {
		object-fit: cover;
		object-position: top center;
	}
}

.html5gallery-container-0,
.html5gallery-container-1,
.html5gallery-container-2,
.html5gallery-container-3,
.html5gallery-container-4,
.html5gallery-container-5,
.html5gallery-container-6,
.html5gallery-container-7,
.html5gallery-container-8,
.html5gallery-container-9{
	width:100% !important;
}
.html5gallery-box-5,
.html5gallery-elem-5,
.html5gallery-box-6,
.html5gallery-elem-6,
.html5gallery-box-8,
.html5gallery-elem-8,
.html5gallery-box-8,
.html5gallery-elem-8,
.html5gallery-box-9,
.html5gallery-elem-9,
.html5gallery-box-0,
.html5gallery-elem-0,
.html5gallery-box-1,
.html5gallery-elem-1,
.html5gallery-box-2,
.html5gallery-elem-2,
.sejour-detail .one-detail iframe,
.html5gallery-container-4,
.html5gallery-container-5,
.html5gallery-container-6,
.html5gallery-container-7,
.html5gallery-container-8,
.html5gallery-container-9,
.html5gallery-container-0,
.html5gallery-container-1,
.html5gallery-container-2,
.html5gallery-box-4,
.html5gallery-box-5,
.html5gallery-box-6,
.html5gallery-box-7,
.html5gallery-box-8,
.html5gallery-box-9,
.html5gallery-box-0,
.html5gallery-box-1,
.html5gallery-box-2,
.html5gallery-car-list-4,
.html5gallery-car-4,
.html5gallery-car-list-5,
.html5gallery-car-5,
.html5gallery-car-list-6,
.html5gallery-car-6,
.html5gallery-car-list-7,
.html5gallery-car-7,
.html5gallery-car-list-8,
.html5gallery-car-8,
.html5gallery-car-list-9,
.html5gallery-car-9,
.html5gallery-car-list-0,
.html5gallery-car-0,
.html5gallery-car-list-1,
.html5gallery-car-1,
.html5gallery-car-list-2,
.html5gallery-car-2{
	width:100% !important;
}
#flypage .html5gallery,
.html5gallery-elem-4,
.html5gallery-elem-5,
.html5gallery-elem-6,
.html5gallery-elem-7,
.html5gallery-elem-8,
.html5gallery-elem-9,
.html5gallery-elem-0,
.html5gallery-elem-1,
.html5gallery-elem-2,
.html5gallery-elem-img-4,
.html5gallery-elem-img-4 img,
.html5gallery-elem-img-5,
.html5gallery-elem-img-5 img,
.html5gallery-elem-img-6,
.html5gallery-elem-img-6 img,
.html5gallery-elem-img-7,
.html5gallery-elem-img-7 img,
.html5gallery-elem-img-8,
.html5gallery-elem-img-8 img,
.html5gallery-elem-img-9,
.html5gallery-elem-img-9 img,
.html5gallery-elem-img-0,
.html5gallery-elem-img-0 img,
.html5gallery-elem-img-1,
.html5gallery-elem-img-1 img,
.html5gallery-elem-img-2,
.html5gallery-elem-img-2 img{
	width:100% !important;
	padding: 0;
}
.html5gallery-elem-4,
.html5gallery-elem-7,
.html5gallery-elem-1{
	padding: 0 !important;
}
.html5gallery-elem-img-4,
.html5gallery-elem-img-5,
.html5gallery-elem-img-6,
.html5gallery-elem-img-7,
.html5gallery-elem-img-8,
.html5gallery-elem-img-9,
.html5gallery-elem-img-0,
.html5gallery-elem-img-1,
.html5gallery-elem-img-2{
	margin-left: 0px !important;
    margin-top: 0px !important;
}
.html5gallery-car-mask-0,
.html5gallery-car-mask-1,
.html5gallery-car-mask-2,
.html5gallery-car-mask-3,
.html5gallery-car-mask-4,
.html5gallery-car-mask-5,
.html5gallery-car-mask-6,
.html5gallery-car-mask-7,
.html5gallery-car-mask-8,
.html5gallery-car-mask-9,
.html5gallery-car-mask-0,
.html5gallery-car-mask-1,
.html5gallery-car-mask-2  {
    width: 85.5% !important;
    left: 65px !important;
}
.html5gallery-elem-0>a,
.html5gallery-elem-1>a,
.html5gallery-elem-2>a,
.html5gallery-elem-3>a,
.html5gallery-elem-4>a,
.html5gallery-elem-5>a,
.html5gallery-elem-6>a,
.html5gallery-elem-7>a,
.html5gallery-elem-8>a,
.html5gallery-elem-9>a{
	display:none !important;
	opacity: 0 !important;
}
.html5gallery-right-4,
.html5gallery-right-5,
.html5gallery-right-6,
.html5gallery-right-7,
.html5gallery-right-8,
.html5gallery-right-9,
.html5gallery-right-0,
.html5gallery-right-1,
.html5gallery-right-2,
.html5gallery-right-3{
    right: 0 !important;
	left:auto !important;
}
.html5gallery-elem-img-4 img,
.html5gallery-elem-img-5 img,
.html5gallery-elem-img-0 img,
.html5gallery-elem-img-1 img,
.html5gallery-elem-img-2 img,
.html5gallery-elem-img-3 img,
.html5gallery-elem-img-6 img,
.html5gallery-elem-img-7 img,
.html5gallery-elem-img-8 img,
.html5gallery-elem-img-9 img{
	left: 0 !important;
    top: 0!important;
    margin-left:0px !important;
    width:100% !important;
    /*height: 350px !important;*/
}
.modal-hotel .html5gallery-elem-img-4 img,
.modal-hotel .html5gallery-elem-img-5 img,
.modal-hotel .html5gallery-elem-img-0 img,
.modal-hotel .html5gallery-elem-img-1 img,
.modal-hotel .html5gallery-elem-img-2 img,
.modal-hotel .html5gallery-elem-img-3 img,
.modal-hotel .html5gallery-elem-img-6 img,
.modal-hotel .html5gallery-elem-img-7 img,
.modal-hotel .html5gallery-elem-img-8 img,
.modal-hotel .html5gallery-elem-img-9 img{
	left: 0 !important;
    top: 0!important;
    height: 600px !important;
}
.modal-header .close {
	margin-top: 5px;
    margin-right: 11px;
    z-index: 9999999999;
    position: absolute;
    right: 0;
	opacity: 1;
}
.numResa i {
    color: theme-color(secondary);
    left: -17px !important;
    top: 0px !important;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-corner-all{
	color:#000 !important;
	font-weight:400;
}
#slides{
	width:383px !important;
	height: 22px;
}
#slides .slide{
	width: 383px !important;
}
#slides ul{
	width: 2681px !important;
	left: -383px !important;
}

a.lire-plus{
	text-decoration: none;
    color: theme-color(secondary);
    font-size: 13px;
    font-weight: 300;
	margin-top: -15px;
    display: block;
}
.gallery-golf{
	margin: 0 auto !important;
}
.detail-golf-sejour .one-detail{
	padding-top:25px;
	padding-bottom:25px;
}
.detail-golf-sejour{
	padding-bottom:50px;
}
.detail-golf-sejour .caracteristique {
    margin-top: 20px;
}
.detail-golf-sejour .info-carac ul li {
    margin-left: 0px; 
    margin-right: 0px !important; 
}
.detail-golf-sejour .one-acceder .desc-acceder p {
    /*padding-top: 10px;*/
}
.detail-golf-sejour  .col-md-6 {
    padding-right: 15px;
    padding-left: 15px;
}
.detail-golf-sejour .bottom-article{
	background:#fff;
	min-height: 116px;
}
#supersized-loader{
	display:none !important;
}

/*-----------------------------------------------------------------------*/
.select-target.select-theme-default {
    padding: 8px 12px;
    color: #9B9B9A;
}
.select.select-theme-default .select-options .select-option {
	font-size: 14px;
}
.select-theme-default, .select-theme-default *, .select-theme-default :after, .select-theme-default :before {
    font-family: Arial,sans-serif;
    font-size: 14px;
}
#choix-hotel-stage select, #nbr_stagiaire select {
    margin-bottom: 10px;
    padding-left: 10px;
    padding: 5px;
}
#choix-hotel-stage label {
    font-size: 16px;
    margin-bottom: 13px;
    display: block;
}
.ui-multiselect-checkboxes label input {
    position: relative;
    top: -1px;
    margin-right: 5px;
}
.select.select-theme-default .select-content {
    -webkit-box-shadow: 0 2px 8px rgba(0,0,0,.2);
    -moz-box-shadow: 0 2px 8px rgba(0,0,0,.2);
    box-shadow: 0 1px 0px rgba(0,0,0,.2);
    background: #EEE;
    overflow: auto;
    max-width: 30rem;
    max-height: 18rem;
    -webkit-overflow-scrolling: touch;
}
.ui-multiselect {
    padding: 2px 0 2px 4px;
    text-align: left;
    width: 100% !important;
    background: #F7F7F6 !important;
    color: #999 !important;
    border: #999 solid 1px;
    padding: 5px;
}
.ui-multiselect-menu {
    display: none;
    padding: 4px;
    position: absolute;
    z-index: 10000;
    text-align: left;
    background: #F7F7F6;
    width: 220px !important;
    border: #999 solid 1px;
    border-top: none;
}
#bloc-actions-mrp {
    background: #F9F9F9;
    border:none; 
    padding: 30px;
    width: 100%;
    float: left;
    font-size: 12px;
    color: #535244;
    margin: 10px 0 20px 0;
}
#articles-col-left .titre-article, #articles-col-left .titre-article h1 {
	color:#333333 !important;
	text-align: center;
	font-size: 30px;
    font-weight: 300;
    margin-bottom: 22px;
    text-transform: uppercase;
    font-family: 'Raleway';
}
#articles-col-left .titre-article strong {
	color:theme-color(secondary) !important;
	margin-top:10px;
}
.paragraphe-entier, .paragraphe-colonne,.article-points {
	color: #545244;
    font-size: 15px;
    line-height: 21px;
    /*margin-top: 40px;*/
    background-color: #F9F9F9;
    padding: 10px;
}
.paragraphe-entier p{
	margin-top:5px;
}
.paragraphe-colonne {
    width: 60%;
    height: 300px;
    background-color: #F9F9F9;
}
.img-colonne{
	width: 40%;
    height: 300px;
}
.img-colonne span{
	width:100%;

}
.img-colonne img{
	width:100%;
	height:100%; 
}
#articles-col-left .droite {
    float: right;
    margin-left: 0px;
}
#articles-col-left .gauche {
    float: left;
    margin-right: 0px;
}
.paragraphe-colonne { 
	padding-top:10px;
}
.paragraphe-colonne p{
	padding-left:10px;
	padding-right:10px;
}
.paragraphe-entier.gris {
    background: #F9F9F9;
    padding: 25px;
    border:0;
}
.paragraphe-entier p, .paragraphe-colonne p {
    margin-bottom: 0px;
    font-weight: 400;
    margin-bottom:10px;
}
#articles-col-left .sous-titre-article h2, #articles-col-left .sous-titre-article-sp p {
    font-size: 30px;
    color: #555244;
    font-size: 16px;
    min-width: 620px;
    clear: both;
    margin-bottom: 20px;
    font-weight: normal;
    margin-top: 20px;
}
.paragraphe-entier p,.article-points p{
	font-size: 15px;
}
@media (max-width: 450px){
	.paragraphe-colonne { 
	    width: 100%;
	    height:auto;
	    background-color: #F9F9F9;
	}
	.img-colonne{
		width: 100%;
	    height: 300px;
	}
	.paragraphe-entier.gris {
	    background: #F9F9F9;
	    padding: 20px;
	    border: 0;
	}
	.paragraphe-entier ul, .paragraphe-colonne ul {
	    margin-left: 0px;
	    margin-bottom: 10px;
	}
}


/*********/
.gm-style-iw {
	width: 520px!important;
    top: 0 !important;
    left: 0 !important;
    background-color: #fff;
    box-shadow: none !important;
    border: 6px solid rgba(0,0,0,0.1);
    border-radius: 0;
}
.gm-style-iw + div > img{
    left: 506px !important ;
}
.gm-style div div div div div div{
	  /*  background-color: transparent !important;*/
    border: 0 !important;
    box-shadow: none !important;
	width: 99% !important;
	cursor:pointer;
}

.gm-style div div div div{
	width: 520px !important;
}
.gm-style-iw a:hover {
    /*background-color: #fcded3;*/
	color: #666;
	text-decoration:none;
	/*display:block;*/
}

#bloc_detail_carte_stage {
    display: block;
    min-height: 60px !important;
	width:100% !important;
}
#bloc_detail_carte_stage:hover {
    background-color: #fcded3 !important;
	color: #666;
}
#bloc_detail_carte_stage .titre{
	padding-top:6px;
}

.informations.info-avis{
	padding-left: 0;
}
.info-carac-first ul li{
	margin-left:0;
}
.label-global{
	padding-top: 8px;
}
.total-note{
	font-weight: bold;
    font-size: 20px;
    line-height: 35px;
}
input#forNoteGlobale{
	font-size: 20px;
    text-align: center;
}
.formRight .row:before,
.formRight .row:after{
	display:none;
}
.html5gallery-box-4 {
    left: 0px;
}
.modal-header {
    padding: 0px;
	min-height: 0;
}
.modal-body {
    padding: 0px;
}
.modal-content {
    position: relative;
    background-color: #f0f0f0;
    border-radius: 0px;
	padding: 0;
    margin: 0;
}
.html5gallery-elem-4 {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.html5gallery-box-0,
.html5gallery-box-10,
.html5gallery-box-1,
.html5gallery-box-2,
.html5gallery-box-3,
.html5gallery-box-4,
.html5gallery-box-5,
.html5gallery-box-6,
.html5gallery-box-7,
.html5gallery-box-8,
.html5gallery-box-9 {
    display: block !important;
    position: absolute !important;
    text-align: center !important;
    left: -4px !important;
    top:-4px !important;
    width: 688px !important;
    /*height: 375px !important;*/
	width: 100% !important;
	margin-left:4px;
}
#html5gallery-elem-video-0,
#html5gallery-elem-video-10,
#html5gallery-elem-video-1,
#html5gallery-elem-video-2,
#html5gallery-elem-video-3,
#html5gallery-elem-video-4,
#html5gallery-elem-video-5,
#html5gallery-elem-video-6,
#html5gallery-elem-video-7,
#html5gallery-elem-video-8,
#html5gallery-elem-video-9{
	width: 100% !important;
	left: 0 !important;
	height: 100% !important;

}
.html5gallery-elem-0,
.html5gallery-elem-10,
.html5gallery-elem-1,
.html5gallery-elem-2,
.html5gallery-elem-3,
.html5gallery-elem-4,
.html5gallery-elem-5,
.html5gallery-elem-6,
.html5gallery-elem-7,
.html5gallery-elem-8,
.html5gallery-elem-9{
    -webkit-box-shadow:none !important;
    box-shadow:none !important;
	/*height:375px !important;*/
	width: 100% !important;
	padding: 0 !important;
}
.modal-hotel .html5gallery-elem-0,
.modal-hotel .html5gallery-elem-10,
.modal-hotel .html5gallery-elem-1,
.modal-hotel .html5gallery-elem-2,
.modal-hotel .html5gallery-elem-3,
.modal-hotel .html5gallery-elem-4,
.modal-hotel .html5gallery-elem-5,
.modal-hotel .html5gallery-elem-6,
.modal-hotel .html5gallery-elem-7,
.modal-hotel .html5gallery-elem-8,
.modal-hotel .html5gallery-elem-9{
    -webkit-box-shadow:none !important;
    box-shadow:none !important;
	height:600px !important;
	width: 100% !important;
	padding: 0;
}
.html5gallery-elem-img-0,
.html5gallery-elem-img-10,
.html5gallery-elem-img-1,
.html5gallery-elem-img-2,
.html5gallery-elem-img-3,
.html5gallery-elem-img-4,
.html5gallery-elem-img-5,
.html5gallery-elem-img-6,
.html5gallery-elem-img-7,
.html5gallery-elem-img-8,
.html5gallery-elem-img-9{
	/*height:375px !important;*/
	width: 100% !important;
	padding: 0;
}
.modal-hotel .html5gallery-elem-img-0,
.modal-hotel .html5gallery-elem-img-10,
.modal-hotel .html5gallery-elem-img-1,
.modal-hotel .html5gallery-elem-img-2,
.modal-hotel .html5gallery-elem-img-3,
.modal-hotel .html5gallery-elem-img-4,
.modal-hotel .html5gallery-elem-img-5,
.modal-hotel .html5gallery-elem-img-6,
.modal-hotel .html5gallery-elem-img-7,
.modal-hotel .html5gallery-elem-img-8,
.modal-hotel .html5gallery-elem-img-9{
	height:600px !important;
	width: 100% !important;
}
.html5gallery-car-0,
.html5gallery-car-10,
.html5gallery-car-1,
.html5gallery-car-2,
.html5gallery-car-3,
.html5gallery-car-4,
.html5gallery-car-5,
.html5gallery-car-6,
.html5gallery-car-7,
.html5gallery-car-8,
.html5gallery-car-9 {
    left: 0px !important;
    top: 100%;
    width: 100% !important;
}
.modal-hotel .html5gallery-car-0,
.modal-hotel .html5gallery-car-10,
.modal-hotel .html5gallery-car-1,
.modal-hotel .html5gallery-car-2,
.modal-hotel .html5gallery-car-3,
.modal-hotel .html5gallery-car-4,
.modal-hotel .html5gallery-car-5,
.modal-hotel .html5gallery-car-6,
.modal-hotel .html5gallery-car-7,
.modal-hotel .html5gallery-car-8,
.modal-hotel .html5gallery-car-9 {
    left: 0px !important;
    top: 600px !important;
    width: 100% !important;
}

.modal-hotel .html5gallery {
    height: 710px !important;
}

.html5gallery-car-list-0,
.html5gallery-car-list-10,
.html5gallery-car-list-1,
.html5gallery-car-list-2,
.html5gallery-car-list-3,
.html5gallery-car-list-4,
.html5gallery-car-list-5,
.html5gallery-car-list-6,
.html5gallery-car-list-7,
.html5gallery-car-list-8,
.html5gallery-car-list-9 {
    left: 0px !important;
    width: 100% !important;
}
.html5gallery-lightbox-0,
.html5gallery-lightbox-10,
.html5gallery-lightbox-1,
.html5gallery-lightbox-2,
.html5gallery-lightbox-3,
.html5gallery-lightbox-4,
.html5gallery-lightbox-5,
.html5gallery-lightbox-6,
.html5gallery-lightbox-7,
.html5gallery-lightbox-8,
.html5gallery-lightbox-9{
	right:0 !important;
	left:auto !important;
}

.html5gallery-pause-0,
.html5gallery-pause-10,
.html5gallery-pause-1,
.html5gallery-pause-2,
.html5gallery-pause-3,
.html5gallery-pause-4,
.html5gallery-pause-5,
.html5gallery-pause-6,
.html5gallery-pause-7,
.html5gallery-pause-8,
.html5gallery-pause-9{
	right:40px !important;
	left:auto !important;
}

.html5gallery-play-0,
.html5gallery-play-10,
.html5gallery-play-1,
.html5gallery-play-2,
.html5gallery-play-3,
.html5gallery-play-4,
.html5gallery-play-5,
.html5gallery-play-6,
.html5gallery-play-7,
.html5gallery-play-8,
.html5gallery-play-9{
	right:40px !important;
	left:auto !important;
}
.html5gallery-car-right-0,
.html5gallery-car-right-1,
.html5gallery-car-right-2,
.html5gallery-car-right-3,
.html5gallery-car-right-4,
.html5gallery-car-right-5,
.html5gallery-car-right-6,
.html5gallery-car-right-7,
.html5gallery-car-right-8,
.html5gallery-car-right-9,
.html5gallery-car-right-10 {
    right: 15px !important;
    width: 32px !important;
    height: 32px !important;
    top: 35px !important;
    background: url('/media/images/right-smd.png') no-repeat 0px 0px;
    background-position: 0 0 !important;
	cursor:pointer !important;
}
.html5gallery-car-left-0,
.html5gallery-car-left-1,
.html5gallery-car-left-2,
.html5gallery-car-left-3,
.html5gallery-car-left-4,
.html5gallery-car-left-5,
.html5gallery-car-left-6,
.html5gallery-car-left-7,
.html5gallery-car-left-8,
.html5gallery-car-left-9,
.html5gallery-car-left-10 {
    left: 15px !important;
    width: 32px !important;
    height: 32px !important;
    top: 35px !important;
    background: url('/media/images/left-smd.png') no-repeat 0px 0px;
    background-position: 0 0 !important;
	cursor:pointer!important;
}
.html5gallery-car-list-5 {    
	top: 8px !important;
}
.html5gallery-left-0,
.html5gallery-right-0,
.html5gallery-left-1,
.html5gallery-right-1,
.html5gallery-left-2,
.html5gallery-right-2,
.html5gallery-left-3,
.html5gallery-right-3,
.html5gallery-left-4,
.html5gallery-right-4,
.html5gallery-left-5,
.html5gallery-right-5,
.html5gallery-left-6,
.html5gallery-right-6,
.html5gallery-left-7,
.html5gallery-right-7,
.html5gallery-left-8,
.html5gallery-right-8,
.html5gallery-left-9,
.html5gallery-right-9{
	top: 170px !important;
}

.modal-hotel .html5gallery-left-0,
.modal-hotel .html5gallery-right-0,
.modal-hotel .html5gallery-left-1,
.modal-hotel .html5gallery-right-1,
.modal-hotel .html5gallery-left-2,
.modal-hotel .html5gallery-right-2,
.modal-hotel .html5gallery-left-3,
.modal-hotel .html5gallery-right-3,
.modal-hotel .html5gallery-left-4,
.modal-hotel .html5gallery-right-4,
.modal-hotel .html5gallery-left-5,
.modal-hotel .html5gallery-right-5,
.modal-hotel .html5gallery-left-6,
.modal-hotel .html5gallery-right-6,
.modal-hotel .html5gallery-left-7,
.modal-hotel .html5gallery-right-7,
.modal-hotel .html5gallery-left-8,
.modal-hotel .html5gallery-right-8,
.modal-hotel .html5gallery-left-9,
.modal-hotel .html5gallery-right-9{
	top: 250px !important;
}
.html5gallery-tn-0,
.html5gallery-tn-1,
.html5gallery-tn-2,
.html5gallery-tn-3,
.html5gallery-tn-4,
.html5gallery-tn-5,
.html5gallery-tn-6,
.html5gallery-tn-7,
.html5gallery-tn-8,
.html5gallery-tn-9,
.html5gallery-tn-10{
	height: 81px !important;
}
.html5gallery-tn-img-0,
.html5gallery-tn-img-1,
.html5gallery-tn-img-2,
.html5gallery-tn-img-3,
.html5gallery-tn-img-4,
.html5gallery-tn-img-5,
.html5gallery-tn-img-6,
.html5gallery-tn-img-7,
.html5gallery-tn-img-8,
.html5gallery-tn-img-9,
.html5gallery-tn-img-10{
	height: 81px !important;
}
.html5gallery-tn-img-0 >div,
.html5gallery-tn-img-1 >div,
.html5gallery-tn-img-2 >div,
.html5gallery-tn-img-3 >div,
.html5gallery-tn-img-4 >div,
.html5gallery-tn-img-5 >div,
.html5gallery-tn-img-6 >div,
.html5gallery-tn-img-7 >div,
.html5gallery-tn-img-8 >div,
.html5gallery-tn-img-9 >div,
.html5gallery-tn-img-10 >div{
	height: 81px !important;
}
.html5gallery-tn-img-0 >div>img,
.html5gallery-tn-img-1 >div>img,
.html5gallery-tn-img-2 >div>img,
.html5gallery-tn-img-3 >div>img,
.html5gallery-tn-img-4 >div>img,
.html5gallery-tn-img-5 >div>img,
.html5gallery-tn-img-6 >div>img,
.html5gallery-tn-img-7 >div>img,
.html5gallery-tn-img-8 >div>img,
.html5gallery-tn-img-9 >div>img,
.html5gallery-tn-img-10 >div>img{
	height: 77px !important;
}
.html5gallery-tn-img-3
.more {
    width: 229px;
    clear: both;
    background: #F3F3F3 !important;
    color: #333;
    cursor: pointer;
}
.more.focus, .more:focus, .more:hover {
    color: #333;
    background: #ccc;
    text-decoration: none;
}


/************/
.article-complet{
	display: inline-block;
	width: 100%;
	background: #f7f7f7;
}
.article-complet .icon-infos{
	width: 100%;
}
.information-coach{
	padding-right: 30px;
	padding-top: 20px;
}
.more-info{
	display: inline-block;
	width: 100%;
}
.midales {
	margin-top: 25px;
	margin-bottom: 25px;
}
.midales .one-info{
	width: 50%;
	float: left;
	margin-bottom: 10px;
}
.stars {
	margin-top: 25px;
	margin-bottom: 15px;
}
.stars .one-info{
	width: 50%;
	float: right;
	text-align: right;
}
.read-more-coach{position: relative;}
/*.article-complet .read-more-coach {
    position: absolute;
    right: 35px;
    bottom: -10px;
}
.read-more-coach a{
	float: right;
	padding: 8px 25px;
	 position: absolute;
    right: 0px;
    top: -50px;
}*/

.read-more-coach,
.read-more-coach a {
	position: relative;
	clear: both;
	width: 100%;
}
.read-more-coach {
	padding: 15px 0 !important;
}
.read-more-coach a {
	display: block;
}

.gris-last-detail{
	position: relative;
}
.gris-last-detail p{
	margin-top:0;
	color:#acacac;
}
.starts-avis{
	margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    right: 65px;
    top: 0;
}
.one-stage-new{
	margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    border: 0;
    box-shadow: none;
}
.one-stage-new .info-stage h6{
    color: #333333;
    text-transform: uppercase;
    font-size: 16px;	}
.one-stage-new .info-stage p{
	color:#8a8a8a;
	margin-top: 5px;
    font-size: 16px;
}
.image-new-stage{
	padding:0;
}
.les-avis{padding-left:0;}
.les-tabs{padding-right:0;padding-left:0;}
.descrip-coach{
	display:inline-block;
	width:100%;
}
.detail-coach{
	display:inline-block;

	width:100%;
}
.explication-coach{
	padding-top:30px;
	padding-bottom:30px;
	margin-top:20px;
	display: inline-block;
	background-color: #f3f3f3;
}
.explication-coach h2{
	font-size: 18px;
    color: #010101;
	position:relative;
	margin-bottom: 30px;
	font-weight:bold;
	background-color: #f3f3f3;

}
.connexion-coach{
	padding-top:30px;
	padding-bottom:30px;
	margin-top:20px;
	display: inline-block;


}
.connexion-coach a{
	margin-right:20px;
}
.connexion-coach h2 {
	font-size: 24px;
	text-align:center;
	margin-bottom: 30px;
}
.connexion-coach .one-input{
	width:100%;
}
.reservation-coach{
	background:#f7f7f7;
	padding-top:30px;
	padding-bottom:30px;
	margin-top:20px;
	display: inline-block;
    width: 100%;
}
.reservation-coach h2{
	font-size: 18px;
    color: #010101;
	position:relative;
	margin-bottom: 30px;
}
.reservation-coach h2:after{
	    content: ' ';
    width: 50px;
    height: 1px;
    background: #000000;
    position: absolute;
    bottom: -7px;
    left: 0;
}
.reservation-coach .block-res{
	width:100%;
	min-height:300px;
	background:#fff;
	padding:40px 20px;
}
.reservation-coach .block-res h4{
	text-transform:uppercase;
	margin-bottom:10px;
	color: #010101;
	font-size: 18px;
	text-align:center;
}
.reservation-coach .form-control {
    border-radius: 0;
    border: 1px solid #929292;
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
}
.reservation-coach .tarif{
	text-align:center;
}
.reservation-coach .tarif span{ 
	font-size: 22px;
    padding: 0 5px;
}
.btn-orange-res{
	background:#f35d20;
	/*margin-top:55px;*/
	margin-top:15px;
	text-transform:uppercase;
}
#calendar {
   /* background: #fff;
    padding: 8px;*/
}
.slider-coach .bx-wrapper .bx-viewport{
	background:transparent;
	border:0;
	box-shadow:none;
	margin-top: 0;
	max-height: 320px;
}
.slider-coach .bx-wrapper{
	margin-bottom:20px;
}
.slider-coach h4{
	margin-bottom:10px;
	text-transform:uppercase;
	font-size: 16px;
}
.slider-coach .bx-wrapper .bx-viewport img{
	width:100% !important;
	height:auto !important;
}
.slider-coach  .bx-controls-direction {
    padding: 0 0 5px 0;
    float: right;
    position: absolute;
    right: 15px;
    width: 25px;
    bottom: -415px;
}
.fc-time-grid-container{
	height: 280px !important;
}
.certif-right-list{
	position: absolute;
    right: 25px;
    top: 10px;
    width: 185px;
}
.certif-right-list .cerfit{
	margin-top:3px;
	width: 40px;
	margin-left:10px;
}
.certification-prod .cerfit{
	margin-top:3px;
	width: 40px;
	margin-left:6px;
}
.certification-prod .cerfit img{
	width: 40px;
}
.info-coach h3 {
    width: 65%;
}
.info-coach p.methode-mrp{
	min-height:22px;
}
.one-ico-last{
	margin-bottom:0;
}
p.avis-coatch{
	float:left;
	margin-right:22px;
}
.one-ico .right-ico .rating-md{
	    display: block;
    width: 140px;
}

#footer-section>ul>li>span {
    font-size: 14px;
    font-weight: normal;
    color: #a0a0a0;
    background: url(../IMAGES/icon-stage-footer.gif) top left no-repeat;
    padding-left: 0px;
    width: 100%;
    height: 18px;
    display: block;
    margin-bottom: 15px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-family: 'Proxima Nova' !important;
    display: inline-table;
}


#footer-section>ul>li>div {
	text-align:center;
	}
#footer-section ul li ul {
    padding-left: 0px;
    float: none !important;
    display: table;
    margin: 0 auto;
    /* margin-left: 4px; */
    background-color: #F7F7F6;
    width: 408px;
    padding: 13px 27px 17px 24px;
    margin-top: 11px;
}
#footer-section ul li ul li {
    list-style:none;
	margin-left:0;
	padding-left:0;
}
#footer-section ul li ul li a{
    color:#333;
}

#fulltext ul li{
	background-color:transparent !important;
	width: 100%;
}
#fulltext .tableau li:nth-child(odd) {
    background: #eeeeee !important;
}
.accreditation{
	    position: absolute;
    right: 0px;
}
.accreditation img{
	width:70px;
}
.block-titre{
	color: #F26126;
    font-weight: 500;
}

#articles-col-left .sous-titre-article p ,#articles-col-left .titre-paragraphe-article h3,.article-points dt,.bloc-lien-pdf a,#carte-cadeau .block-titre,#content-apercu-carte .block-titre{
    font-family: 'Raleway' !important;
}
#footer-section>ul>li {
    border-bottom: none !important;
}
.fc-scroller.fc-time-grid-container {
    height: auto !important;
}
.img-entier.gauche {
    margin-bottom: 50px !important;
    margin-top: 50px !important;
}
.certif-right .cerfit_right {
    float: left;
    width: 24px;
    height: 25px;
    margin-right: 29px;
}
.btn-danger,.btn-danger.active,.btn-danger.active.focus,.btn-danger:focus {
    color: #fff;
    background-color: #f35d20;
    border-color: #f35d20;
}
.btn-danger:hover {
    color: #fff;
    background-color: #f35d20;
    border-color: #f35d20;
}
.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open>.dropdown-toggle.btn-danger.focus, .open>.dropdown-toggle.btn-danger:focus, .open>.dropdown-toggle.btn-danger:hover {
    color: #fff;
    background-color: #f35d20;
    border-color: #f35d20;
}

#content, body {
    width: 100%!important;
}


/*---------------------------------------------------------*/
@media (max-width: 450px){
		.contenu {
		    padding-top: 0px;
		    padding-bottom: 65px;
		}
		.container {
		    padding-right: 15px;
		    
		    margin-right: auto;
		    margin-left: auto;
		}
		.last-avis {
		    background-color: #f3f3f3;
		    position: relative;
		    padding: 15px;
		    /* padding-left: 35px; */
		}
		.info-coach{
			margin-top: 20px;
		}
		.info-coach .top-coach{
			display: inline-block;
		    width: 100%;
		    margin-bottom: 35px;
			background:#f7f7f7;
		}
		.info-coach .photo{padding-left:0;}
		.info-coach .img-coach{
			
		}
		.info-coach .informations{
			padding-left:15px;
			padding-top:30px;
			padding-right: 50px;
		}

		.info-coach .informations h1{
			font-size: 24px;
		    font-weight: normal;
		    color: #f35d20;
		    position: relative;
		    margin-bottom: 15px;
			position:relative;
		}
		.info-coach .informations h1:after {
		    content: ' ';
		    width: 50px;
		    height: 1px;
		    background: #000000;
		    position: absolute;
		    bottom: -10px;
		    left: 0;
		}
		.info-coach .informations p{
			font-size: 14px;
		    font-weight: 300;
		    color: rgb(102, 102, 102);
		}
		.info-coach .informations a{
			text-decoration: none;
		    color: rgb(242, 93, 32);
		    font-size: 13px;
		    font-weight: 300;
			/*float: left;*/
			margin-bottom:10px;
		}
		
	}
	.disponibilite{
		display: inline-block;
    	margin-top: 10px;
	}
	.disponibilite:after,.disponibilite::after{
		
	}
	.disponibilite:before,.disponibilite::before{
		content: "";
		width: 15px;
		height: 15px;
		background-color: #e0f3d7;
		border:1px solid #ccc;
		margin-right: 10px;
		display: inline-block;
	}
	.reserve{
		display: inline-block;
    	margin-top: 10px;
	}
	.reserve:after,.reserve::after{
		
	}
	.reserve:before,.reserve::before{
		content: "";
		width: 15px;
		height: 15px;
		background-color: #F4936A;
		border:1px solid #ccc;
		margin-right: 10px;
		display: inline-block;
	}
	#enseignant_carte{
		width:100%;
		height:410px;
	}
	.enseigant_info{
	    height: 30px;
	    line-height: 30px;
	    /*padding-left: 13px;*/
	}
.lien_ecoach{
	color: #FFF !important;
    font-weight: bold !important;
    width: 121px !important;
    display: inline-block !important;
    float: right !important;
	
}
.h-scroll {
    height: 405px;
        width: 100%;
        overflow: auto;
        float: left;
        position: relative;
        margin-left: -5px;
}
/* Page carte cadeau
/* --------------------------------------------------------------------------------------------------------------- */
.content-cadeau{
	background-image: url(../images/golf-cadeau.jpg) ;
	background-position: center center;
	background-repeat:  no-repeat;
	
	background-size:  cover;
	background-color: #999;
	width: 100%;
    height: auto;
    position: relative;
    display: inline-block;
}
.content-cadeau .bg-carte{
	background: rgba(51, 51, 51, 0.55);
	width: 100%;
    height: auto;
}
.top-golf-cadeau{
	padding-top: 35px;
	display: inline-block;
    width: 100%;
}
.top-golf-cadeau .label-cadeau{
	padding: 0;
}
.top-golf-cadeau .control-label{
	color: #fff;
    line-height: 34px;
    text-transform: uppercase;
    letter-spacing: 15px;
    font-size: 25px;
    font-family: 'Raleway'
}
.top-golf-cadeau .form-control{
	height: 34px;
	border-radius: 0;
	border:0;
	background: #f2f2f2;
}
.top-golf-cadeau .form-control:focus{
	background: #fff;
	-webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.center-golf-cadeau{
	margin-top: 175px;
	position: relative;
	display: inline-block;
	width: 100%;
}
.center-golf-cadeau .verti{
	position: absolute;
	top: 100px;
}
.center-golf-cadeau .left-verti{
    left: -130px;
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.center-golf-cadeau .right-verti{
	right: -160px;
	-ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.center-golf-cadeau .verti p{
	color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 5px;
    font-family: 'Raleway';
}
.center-info{
	margin-bottom: 120px;
	display: inline-block;
    width: 100%;
}
.center-info .euro{
	font-family: 'Raleway';
}
.center-info .euro h2{
	color: #eb6120;
    font-size: 165px;
    font-weight: 900;
    letter-spacing: 4px;
    padding-top: 75px;
    font-family: 'Raleway';
}
.center-info .euro h2 span{
	font-size: 80px
}
.center-info .info-carte{
	font-family: 'Raleway';
}
.center-info .info-carte h2{
	color: #eb6120;
    font-size: 70px;
    font-weight: 900;
    letter-spacing: 20px;
    text-transform: uppercase;
    font-family: 'Raleway';
}
.center-info .info-carte hr{
	width: 90%;
	height: 7px;
	background: #fff;
	margin-bottom: 25px;
	margin-top: 10px;
}
.center-info .info-carte h4{
	color: #fff;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 20px;
    text-transform: uppercase;
    line-height: 40px;
}
.bottom-golf-cadeau{
	padding-bottom: 20px;
}
.bottom-golf-cadeau p{
	color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 10px;
    font-family: 'Raleway';
}
@media (max-width: 767px){
	.center-golf-cadeau {
	    margin-top: 120px;
	}
	.center-info .euro h2 {
	    padding-top: 0; 
	}
	.center-info .info-carte {
	    text-align: center;
	}
	.center-info .info-carte hr {
	    margin: 0 auto;
	    margin-bottom: 25px;
	    margin-top: 10px;
	    width: 70%;
	}
	.bottom-golf-cadeau p {
	    font-size: 15px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1200px){
	.top-golf-cadeau .control-label {
	    font-size: 22px;
	}
}
@media (max-width: 992px){
	.center-info .euro h2 {
	    font-size: 100px;
	    text-align: center;
	}
	.center-info .info-carte h2 {
	    font-size: 50px;
	}
	.center-info .info-carte h4 {
	    font-size: 14px;
	}
	.top-golf-cadeau .control-label {
	    font-size: 13px;
	}
}
@media screen and (min-width: 768px) and (max-width: 860px){
	.center-golf-cadeau .right-verti {
	    right: -150px;
	}
}
@media (max-width: 520px){
	.top-golf-cadeau .control-label {
	    font-size: 10px;
	}
	.center-golf-cadeau {
	    margin-top: 50px;
	}
	.center-golf-cadeau .verti {
	    position: relative;
	    top: 0;
	    text-align: center;
	}
	.center-golf-cadeau .left-verti {
	    left: 0;
	    -ms-transform: rotate(0deg);
	    -webkit-transform: rotate(0deg);
	    transform: rotate(0deg);
	}
	.center-golf-cadeau .right-verti {
	    right: -0;
	    -ms-transform: rotate(0deg);
	    -webkit-transform: rotate(0deg);
	    transform: rotate(0deg);
	}
	.center-golf-cadeau .verti p {
	    font-size: 10px;
	}
	.center-info .euro h2 {
	    font-size: 50px;
	}
	.center-info .euro h2 span{
	    font-size: 30px;
	}
	.center-info .info-carte h2 {
	    font-size: 40px;
	}
	.center-info .info-carte hr {
	    width: 100%;
	}
	.center-info .info-carte h4 {
	    font-size: 15px;
	    letter-spacing: 10px;
	    line-height: 30px;
	}
	.center-info {
	    margin-bottom: 20px;
	}
	.bottom-golf-cadeau p {
	    font-size: 10px;
	    letter-spacing: 5px;
	}
	.bottom-golf-cadeau {
	    margin-top: 40px;
	}
}




/***Modif 26/07/2016********/
.level .one-level .desc-level {
    padding: 25px;
    min-height: 515px;
}
.paragraphe-entier{
	background: #f9f9f9;
	padding: 10px;
	margin-bottom: 35px;
}
.paragraphe-entier h2 {
    font-weight: bold;
    margin-bottom: 35px;
    color: #000;
}
.paragraphe-entier p{
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 20px;
}
.paragraphe-entier .call {
    font-weight: bold;
    text-decoration: underline;
}
.paragraphe-entier .call a{
    color: #000;
    cursor: pointer;
}
.temoignages {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;
}
.top-temoignages {
    margin-bottom: 70px;
}
.top-temoignages h2 {
   	font-weight: bold;
    margin-bottom: 35px;
    color: #000;
}
.temoignages .bx-controls-direction{display: none;}

.center-info .euro h2 {padding-top: 45px;}
.center-info .euro p{
	color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.center-info .info-carte p{
	color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-right: 50px;
}
.bx-wrapper .bx-viewport{
	box-shadow: none !important;
	border:0 !important;
}
@media (max-width: 1200px){
	.center-info .euro p {font-size: 22px;}
}
@media (max-width: 992px){
	.center-info .euro p {font-size: 15px;}
}
@media (min-width: 767px) and (max-width: 992px){
	.paragraphe-entier, .niveau-mrp {display: inline-block;}
	.level .one-level .desc-level {min-height: 330px;}
}
@media (max-width: 767px){
	.list-temoignages,
	.temoignages .slide{
		width: 100% !important;
	}
	.level .one-level .desc-level {
		min-height: auto;
	}
	.center-info .euro p {
	    text-align: center;
	    margin-bottom: 20px;
	}
	.center-info .info-carte p{padding-right: 0;}
}
@media (max-width: 520px){
	.center-info .euro p {font-size: 11px;}
}

.cerfit_right_detail, .accreditation_detail{
	margin-top: 3px;
    width: 40px;
    margin-left: 10px;
	float: left;
    margin-right: 5px;
}
.accreditation_detail{
	width: 70px;
}
.accreditation_detail img{width:100%;}
.bx-viewport .slide{
	padding-left:0;
}
.bx-viewport .slide .desc-temoignage{
	padding: 35px 50px;
	height: 166px;
}
#content-apercu-carte .btn-close a {
	height: 35px;
	background: #EB6120;
    border-radius: 0;
}
#content-apercu-carte .points-carte span{
	line-height: 25px;
}
.level .one-level .desc-level.levelmrp {
    padding: 25px; 
    min-height: 300px;
}

.p_paragraphe{
	margin-bottom:32px !important;
}
.bold {
    font-weight: bold !important;
}
.souligner{
	text-decoration : underline !important;
}
.description ul li {
    list-style-type: circle !important;
}
.right-golf .acces-golf-flypage{
	display: inline-block; 
}
.content-stages .description p{
    margin-top: 0; 
    margin-bottom: 9px !important;  
}