.carousel {
    z-index: 100;
}

body:not(.index) {
    .carousel {
        @media(min-width: 992px) {
            margin-top: 118px;
        }
        margin-bottom: 30px;
    }
}

.carousel-indicators {
    display: none !important;
}

.carousel-inner {
    overflow: visible;
}

body.index {
    .carousel-caption {
        @extend .box;
        left: 50%;
        transform: translateX(-50%);
        top: auto !important;
        bottom: -90px;
        font-size: 16px !important;
        font-weight: bold;
        color: theme-color(primary);
        text-shadow: none;
        text-transform: uppercase;
        width: 50%;
        height: 170px;

        @media(min-width: 992px) and (max-width: 1199px) {
            width: 80%;
        }

        @media(max-width: 991px) {
            width: 90%;
            height: 200px;
            position: static;
            transform: none;
            margin: 15px auto 0 auto;
        }

        a {
            font-size: 16px;
        }
    }
}


.carousel-control.left, .carousel-control.right {
    background-image: none;
    background: transparent;

    .left-carousel-arrow, .right-carousel-arrow {
        width: 52px;
        height: 52px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .left-carousel-arrow {
        left: 50%;
    }
    .right-carousel-arrow {
        right: 50%;
    }
}
.carousel-caption .button {
    margin-top: 15px;
}

body.index {
    .carousel-caption {
        bottom: -85px;
        height: auto;
        min-height: 200px;
        
        @media (max-width: 991px) {
            bottom: -115px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            text-transform: none !important;
            height: auto;
            padding: 15px;
            font-size: 14px !important;
            line-height: 1.3;
            font-weight: normal;

            .button {
                padding: 10px 20px;
                text-transform: none;
            }
        }
    }
}

.owl-carousel .owl-item > div[class^="col-"] {
    float: none !important;
    width: initial !important; 
    padding:0;
}
